
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { Trans } from "@lingui/macro";
import { ContactForm } from "./components/ContactForm";
import { DarkCard } from "components/Card";
import { RowCenter } from "components/Row";
import { isMobile } from "utils/userAgent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSignature, faGlobe, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { ContactPhone } from "./components/ContactPhone";
import { ContactNetworks } from "./components/ContactNetworks";

const PageWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 8rem;
  background:  ${({ theme }) => theme.primary1};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 7rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const Divider = styled.div`
  width: 600px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary1};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;



export function CustomerService() {
  return (
    <PageWrapper>
      <ThemedText.ExtraLargeHeader color="text2" textAlign="center" my="2rem">
        <Trans>Connecting dreams, creating solutions: Contact us today</Trans>!
      </ThemedText.ExtraLargeHeader>
      <RowCenter>
        <DarkCard width={isMobile ? "100%" : "1200px"} mb="2rem">
          <Row>
            <Divider />
            <FontAwesomeIcon icon={faPhoneVolume} fontSize="40px" color="#CCEDFC" />
            <Divider />
          </Row>
          <ContactPhone />
          <Row>
            <Divider />
            <FontAwesomeIcon icon={faGlobe} fontSize="40px" color="#CCEDFC" />
            <Divider />
          </Row>
          <ContactNetworks />
          <Row>
            <Divider />
            <FontAwesomeIcon icon={faFileSignature} fontSize="40px" color="#CCEDFC" />
            <Divider />
          </Row>
          <ContactForm />
        </DarkCard>
      </RowCenter>
    </PageWrapper>
  );
}
