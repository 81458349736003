import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";

const Card = styled(Box) <{
  width?: string;
  padding?: string;
  border?: string;
  $borderRadius?: string;
}>`
  width: ${({ width }) => width ?? "100%"};
  padding: ${({ padding }) => padding ?? "1rem"};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? "16px"};
  border: ${({ border }) => border};
`;
export default Card;

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bgCard1};
`;

export const LightGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg5};
`;

export const GreyCard = styled(Card)`
  background: ${({ theme }) => theme.bg0};
`;

export const DarkGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg6};
`;

export const DarkCard = styled(Card)`
  background-color: ${({ theme }) => theme.bgCard0};
`;

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg3};
`;

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.yellow3};
  font-weight: 500;
`;

export const BlueCard = styled(Card)`
  background: linear-gradient(90deg, rgba(37,47,55,1) 0%, rgba(38,74,107,1) 70%);
  color: ${({ theme }) => theme.text2};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.secondary1};
  transition: all 0.3s ease;
  :hover {
    transform: scale(1.1);
  }
`;


export const DarkBlueCard = styled(Card)`
  background:  ${({ theme }) => theme.primary4};
  color: ${({ theme }) => theme.text2};
`;