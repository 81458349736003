import { faChevronDown, faChevronUp, faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { ButtonEmpty, ButtonPrimary } from 'components/Button';
import { DarkGreyCard, LightGreyCard } from 'components/Card';
import { AutoColumn } from 'components/Column';
import { useActiveLocale } from 'hooks/useActiveLocale';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components/macro';
import { ThemedText } from 'theme';

const StyledCookies = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        bottom: 20px;
        right: 20px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        bottom: 0;
        right: 0;
    `};
`;

const StyledDarkGreyCard = styled(DarkGreyCard)`
    width: 500px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6); 
    transition: box-shadow 0.3s ease;
    &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6); 
    }
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 100%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    `};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`;

const RowBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ResponsiveBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        gap: 0.5rem;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        gap: 0.5rem;
    `};
`;

const StyledButtonPrimary = styled(ButtonPrimary)`
    width: fit-content;
    height: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
    transition: box-shadow 0.3s ease;
    font-size: 14px;
    &:hover {
        background: ${({ theme }) => theme.secondary2};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
    }
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: fit-content;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
    `};
`;

const CookieBanner: React.FC = () => {
    const currentLanguage = useActiveLocale();
    const [cookies, setCookie] = useCookies(['statistics', 'marketing', 'cookieAction']);
    const [cookieAccepted, setCookieAccepted] = useState(false);
    const [statisticsEnabled, setStatisticsEnabled] = useState(false);
    const [marketingEnabled, setMarketingEnabled] = useState(false);
    const [loading, setLoading] = useState(true);

    const [activeDropdown, setActiveDropdown] = useState<{ isActive: boolean }[]>([
        { isActive: false },
        { isActive: false },
        { isActive: false }
    ]);

    const [enableCookie, setEnableCookie] = useState<{ isActive: boolean }[]>([
        { isActive: false },
        { isActive: false }
    ]);

    const toggleStateItem = (index: number, setState: React.Dispatch<React.SetStateAction<{ isActive: boolean }[]>>) => {
        setState(prevState =>
            prevState.map((item, i) =>
                i === index ? { ...item, isActive: !item.isActive } : item
            )
        );
    };

    const [show, setShow] = useState(false)


    useEffect(() => {
        const checkCookieAction = () => {
            if (cookies.cookieAction) {
                setCookieAccepted(true);
            }
            setLoading(false);
        };
        checkCookieAction();
    }, [cookies.cookieAction]);

    useEffect(() => {
        if (cookieAccepted) {
            if (cookies.statistics && cookies.marketing) {
                setStatisticsEnabled(cookies.statistics === 'true');
                setMarketingEnabled(cookies.marketing === 'true');
            }
        }
    }, [cookieAccepted, cookies.statistics, cookies.marketing]);

    const acceptAllCookies = () => {
        setCookie('statistics', 'true', { path: '/' });
        setCookie('marketing', 'true', { path: '/' });
        setCookie('cookieAction', 'accepted', { path: '/' });
        setCookieAccepted(true);
    };

    const rejectCookies = () => {
        setCookie('statistics', 'false', { path: '/' });
        setCookie('marketing', 'false', { path: '/' });
        setCookie('cookieAction', 'accepted', { path: '/' });
        setCookieAccepted(true);
    };

    const saveSelection = () => {
        setCookie('statistics', statisticsEnabled ? 'true' : 'false', { path: '/' });
        setCookie('marketing', marketingEnabled ? 'true' : 'false', { path: '/' });
        setCookie('cookieAction', 'accepted', { path: '/' });
        setCookieAccepted(true);
    };

    if (loading) {
        return null; // Do not render cookie bar while verification is loading
    }

    if (cookieAccepted) {
        return null; // Do not render the cookie bar if cookies have already been accepted.
    }

    return (
        // Render cookie bar if cookies were not accepted
        <StyledCookies>
            <StyledDarkGreyCard>
                <AutoColumn gap="1rem">
                    <ThemedText.MediumHeader color="text1" textAlign="center">
                        <Trans>Managing Cookie Consent</Trans>
                    </ThemedText.MediumHeader>
                    <ThemedText.SubHeader color="text1" textAlign="justify">
                        <Trans>To provide the best experiences, we use technologies such as cookies to store and/or access device information. Consenting to these technologies allows us to process data such as browsing behavior or unique identifiers on this site. Not consenting or withdrawing consent may negatively affect certain features and functions.</Trans>
                        <a href={`https://vidpay.es/documents/cookie-policy-${currentLanguage}.pdf`} style={{ textDecoration: 'none' }}>
                            <Trans>Cookie Policy</Trans>
                        </a>
                    </ThemedText.SubHeader>

                    {show && <AutoColumn gap="1rem">
                        <LightGreyCard>
                            <RowBetween>
                                <ThemedText.Body>
                                    <Trans>Functional</Trans>
                                </ThemedText.Body>
                                <Row>
                                    <ThemedText.Body color="primary1">
                                        <Trans>Always Active</Trans>
                                    </ThemedText.Body>
                                    <ButtonEmpty width="fit-content" padding="0" onClick={() => toggleStateItem(0, setActiveDropdown)}>
                                        <FontAwesomeIcon icon={activeDropdown[0].isActive ? faChevronUp : faChevronDown} fontSize="20px" />
                                    </ButtonEmpty>
                                </Row>
                            </RowBetween>
                            {activeDropdown[0].isActive &&
                                <ThemedText.SubHeader textAlign="justify" mt="1rem">
                                    <Trans>The technical storage or access is strictly necessary for the legitimate purpose of enabling the use of a specific service explicitly requested by the subscriber or user, or for the sole purpose of carrying out the transmission of a communication over an electronic communications network.</Trans>
                                </ThemedText.SubHeader>
                            }
                        </LightGreyCard>
                        <LightGreyCard>
                            <RowBetween>
                                <ThemedText.Body>
                                    <Trans>Statistics</Trans>
                                </ThemedText.Body>
                                <Row>
                                    <ButtonEmpty width="fit-content" padding="0" onClick={() => toggleStateItem(0, setEnableCookie)}>
                                        <FontAwesomeIcon icon={enableCookie[0].isActive ? faSquareCheck : faSquare} fontSize="20px" />
                                    </ButtonEmpty>
                                    <ButtonEmpty width="fit-content" padding="0" onClick={() => toggleStateItem(1, setActiveDropdown)}>
                                        <FontAwesomeIcon icon={activeDropdown[1].isActive ? faChevronUp : faChevronDown} fontSize="20px" />
                                    </ButtonEmpty>
                                </Row>
                            </RowBetween>
                            {activeDropdown[1].isActive &&
                                <ThemedText.SubHeader textAlign="justify" mt="1rem">
                                    <Trans>The technical storage or access that is used exclusively for anonymous statistical purposes. Without a subpoena, voluntary compliance on the part of your Internet Service Provider, or additional records from a third party, information stored or retrieved for this purpose alone cannot usually be used to identify you.</Trans>
                                </ThemedText.SubHeader>
                            }
                        </LightGreyCard>
                        <LightGreyCard>
                            <RowBetween>
                                <ThemedText.Body>
                                    Marketing
                                </ThemedText.Body>
                                <Row>
                                    <ButtonEmpty width="fit-content" padding="0" onClick={() => toggleStateItem(1, setEnableCookie)}>
                                        <FontAwesomeIcon icon={enableCookie[1].isActive ? faSquareCheck : faSquare} fontSize="20px" />
                                    </ButtonEmpty>
                                    <ButtonEmpty width="fit-content" padding="0" onClick={() => toggleStateItem(2, setActiveDropdown)}>
                                        <FontAwesomeIcon icon={activeDropdown[2].isActive ? faChevronUp : faChevronDown} fontSize="20px" />
                                    </ButtonEmpty>
                                </Row>
                            </RowBetween>
                            {activeDropdown[2].isActive &&
                                <ThemedText.SubHeader textAlign="justify" mt="1rem">
                                    <Trans>The technical storage or access is required to create user profiles to send advertising, or to track the user on a website or across several websites for similar marketing purposes.</Trans>
                                </ThemedText.SubHeader>
                            }
                        </LightGreyCard>
                    </AutoColumn>}
                    <ResponsiveBox>
                        <StyledButtonPrimary onClick={acceptAllCookies}>
                            <Trans>Accept All</Trans>
                        </StyledButtonPrimary>
                        <StyledButtonPrimary onClick={rejectCookies}>
                            <Trans>Reject Optional</Trans>
                        </StyledButtonPrimary>
                        {show ?
                            <StyledButtonPrimary onClick={saveSelection}>
                                <Trans>Save Preferences</Trans>
                            </StyledButtonPrimary>
                            :
                            <StyledButtonPrimary onClick={() => setShow(!show)}>
                                <Trans>Show Preferences</Trans>
                            </StyledButtonPrimary>}
                    </ResponsiveBox>
                </AutoColumn>
            </StyledDarkGreyCard>
        </StyledCookies>
    );
};

export default CookieBanner;
