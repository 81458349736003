import Slide from "react-reveal";
import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import Devices from "assets/images/TV/devices.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { AutoColumn } from "components/Column";
import { ButtonLink } from "components/Button";
import { RowCenter } from "components/Row";

const PageWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    padding: 1rem;
    margin: auto;
    margin-top: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        max-width: 800px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        max-width: 350px;
    `};
`;

const ResponsiveBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 5rem;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
    `};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
`;

const Image = styled.img`
    width: 400px;
    border-radius: 16px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 100%;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
    `};
`;


export function TvInfo() {
    return (
        <PageWrapper>
            <Slide left>
                <ResponsiveBox>
                    <Image src={Devices} alt="devices-img" />
                    <AutoColumn style={{ gap: '1rem' }}>
                        <Row>
                            <FontAwesomeIcon icon={faCircleCheck} fontSize={30} color="#5B83B8" />
                            <ThemedText.MediumHeader color="text1">
                                <Trans>A technological tool for watching television on any device</Trans>.
                            </ThemedText.MediumHeader>
                        </Row>
                        <Row>
                            <FontAwesomeIcon icon={faCircleCheck} fontSize={30} color="#5B83B8" />
                            <ThemedText.MediumHeader color="text1">
                                <Trans>Over 160 channels with the best content for the whole family</Trans>.
                            </ThemedText.MediumHeader>
                        </Row>
                        <Row>
                            <FontAwesomeIcon icon={faCircleCheck} fontSize={30} color="#5B83B8" />
                            <ThemedText.MediumHeader color="text1">
                                <Trans>Hire for any fiber+mobile pack and get this service completely free</Trans>.
                            </ThemedText.MediumHeader>
                        </Row>
                        <ThemedText.ExtraLargeHeader color="green1" textAlign="center">
                            9€/ <Trans>Month</Trans>
                        </ThemedText.ExtraLargeHeader>
                        <RowCenter>
                            <ButtonLink width="fit-content" to="/calcular/99991">
                                <ThemedText.LargeHeader color="#fff">
                                    <Trans>Hire</Trans>
                                </ThemedText.LargeHeader>
                            </ButtonLink>
                        </RowCenter>
                    </AutoColumn>
                </ResponsiveBox>
            </Slide>
        </PageWrapper>
    );
}
