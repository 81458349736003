import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import Logo from "assets/logo-x.png"
import LogoMobile from "assets/logo.png"
import { isMobile } from "utils/userAgent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook, faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
import { ButtonLink } from "components/Button";
import { faClock, faEarthEurope, faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { RowCenter } from "components/Row";
import { useActiveLocale } from "hooks/useActiveLocale";

const FooterFrame = styled.div`
  width: 100%;
  padding: 2rem;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: 0 -100%;
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px theme.bg2;
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  `};
`;


const ResponsiveBox = styled.div`
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #5B83B8;
  padding: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  `};
`;

const ResponsiveSections = styled.div`
  width: 60%;
  margin: auto;
  display: grid;
  grid-template-columns: 1.2fr 0.7fr 0.8fr 0.8fr;
  padding: 1rem;
  border-bottom: 1px solid #5B83B8;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
  `};
`;

const TitleSections = styled(ThemedText.MediumHeader)`
  color: ${({ theme }) => theme.primary1};
  text-transform: uppercase;
`;


const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;


const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SocialNetworks = styled.a`
  transition: all 0.3s ease;
  :hover {
    color: ${({ theme }) => theme.primary1};
    transform: scale(0.8);
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  background: transparent;
  width: fit-content;
  padding: 0;
  z-index: 0;
  color: ${({ theme }) => theme.text1};
  :hover {
    color: ${({ theme }) => theme.primary1};
    background: transparent;
  }
`;


const ResponsiveText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 1rem;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1rem;
    gap: 2rem;
  `};
`;



export default function Footer() {
  const currentLanguage = useActiveLocale();
  return (
    <FooterFrame>
      <ResponsiveBox>
        <img src={isMobile ? LogoMobile : Logo} alt="logo" width={isMobile ? "180px" : "300px"} />
        <Row>
          <ThemedText.Body>
            <Trans>Follow us</Trans>
          </ThemedText.Body>
          <SocialNetworks href="https://www.instagram.com/vidpaytelecom?igsh=ZXo5aHUxdTBsN3hl" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faSquareInstagram} fontSize="40px" />
          </SocialNetworks>
          <SocialNetworks href="https://www.facebook.com/vidpay.telecom" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faSquareFacebook} fontSize="40px" />
          </SocialNetworks>
        </Row>
      </ResponsiveBox>
      <ResponsiveSections>
        <Column>
          <TitleSections>
            <Trans>About Us</Trans>
          </TitleSections>
          <ThemedText.Body>Vidpay Telecom</ThemedText.Body>
          <Row>
            <FontAwesomeIcon icon={faClock} color="#5B83B8" />
            <ThemedText.Body>
              <Trans>Monday to Friday | 10:00 AM-8:00 PM</Trans>
            </ThemedText.Body>
          </Row>
          <Row>
            <FontAwesomeIcon icon={faLocationDot} color="#5B83B8" />
            <ThemedText.Body>Plaza De España 12 Local B, 12550</ThemedText.Body>
          </Row>
          <Row>
            <FontAwesomeIcon icon={faEarthEurope} color="#5B83B8" />
            <ThemedText.Body>Almassora, Castelló, Spain</ThemedText.Body>
          </Row>
          <Row>
            <FontAwesomeIcon icon={faPhone} color="#5B83B8" />
            <a href="tel:+34648136914">648 13 69 14</a>
          </Row>
          <Row>
            <FontAwesomeIcon icon={faEnvelope} color="#5B83B8" />
            <a href="mailto:info@vidpay.es">info@vidpay.es</a>
          </Row>
        </Column>
        <Column>
          <TitleSections>
            <Trans>Rates</Trans>
          </TitleSections>
          <StyledButtonLink to="/fibra-movil">
            <Trans>Fibre and Mobile</Trans>
          </StyledButtonLink>
          <StyledButtonLink to="/fibra">
            <Trans>Fibre</Trans>
          </StyledButtonLink>
          <StyledButtonLink to="/movil">
            <Trans>Mobile</Trans>
          </StyledButtonLink>
        </Column>
        <Column>
          <TitleSections>
            <Trans>Other Services</Trans>
          </TitleSections>
          <StyledButtonLink to="/tv">
            <Trans>TV</Trans>
          </StyledButtonLink>
        </Column>
        <Column>
          <TitleSections>
            <Trans>Help Centre</Trans>
          </TitleSections>
          <StyledButtonLink to="/atencion-cliente">
            <Trans>Customer Service</Trans>
          </StyledButtonLink>
        </Column>
      </ResponsiveSections>
      {isMobile &&
        <RowCenter mt="1rem">
          <ThemedText.Body>
            © {new Date().getFullYear()} VidPay
          </ThemedText.Body>
        </RowCenter>}
      <ResponsiveText>
        {!isMobile &&
          <ThemedText.Body>
            © {new Date().getFullYear()} VidPay
          </ThemedText.Body>}
        {!isMobile && <div>&nbsp; | &nbsp;</div>}
        <a href={`/documents/legal-disclaimer-${currentLanguage}.pdf`}
          style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ThemedText.Body>
            <Trans>Legal Disclaimer</Trans>
          </ThemedText.Body>
        </a>
        {!isMobile && <div>&nbsp; | &nbsp;</div>}
        <a href={`/documents/privacy-policy-${currentLanguage}.pdf`}
          style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ThemedText.Body>
            <Trans>Privacy Policy</Trans>
          </ThemedText.Body>
        </a>
        {!isMobile && <div>&nbsp; | &nbsp;</div>}
        <a href={`/documents/cookies-policy-${currentLanguage}.pdf`}
          style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ThemedText.Body>
            <Trans>Cookie Policy</Trans>
          </ThemedText.Body>
        </a>
      </ResponsiveText>
    </FooterFrame>
  );
}
