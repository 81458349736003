import { Trans } from "@lingui/macro";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import MobilePacks from "./json/mobile.json"
import { useActiveLocale } from "hooks/useActiveLocale";
import { ButtonLink, ButtonPrimary } from "components/Button";
import { RowCenter, RowRight } from "components/Row";
import FormData from 'form-data';
import Mailgun from 'mailgun.js';
import { EnvelopeIcon, ModalContent, ModalOverlay } from 'components/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const PageWrapper = styled(AutoColumn)`
    width: 100%;
`;

const FormContainer = styled.form`
  width: 100%;
`;

const StyledRowRight = styled(RowRight)`
  gap: 1rem;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: center;
    align-items: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
    align-items: center;
  `};
`;

type FormValues = {
    name: string;
    lastName: string;
    identity: string;
    address: string;
    phone: string;
    email: string;
};

interface SubmitDataProps {
    selectedTariff: string;
    mobileLines: number[];
    tv: boolean;
    totalPrice: number;
    formValues: FormValues
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>
    nextPhase: number
    setNextPhase: React.Dispatch<React.SetStateAction<number>>
}


export function SubmitData({ selectedTariff, mobileLines, tv, totalPrice, formValues, setFormValues, nextPhase, setNextPhase }: SubmitDataProps) {
    const currentLanguage = useActiveLocale()
    const navigate = useNavigate()
    let arrayMobileLines: string[] = [];
    for (let i = 0; i < mobileLines.length; i++) {
        if (mobileLines[i] !== 0) {
            arrayMobileLines.push(`${mobileLines[i]}x ${MobilePacks[i].name}`)

        }
    }

    const [showConfirmation, setShowConfirmation] = useState(false)
    //Submit
    const API_KEY: any = process.env.REACT_APP_API_KEY
    const DOMAIN: any = process.env.REACT_APP_MAILGUN_DOMAIN
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const mailgun = new Mailgun(FormData)
            const client = mailgun.client({ username: 'api', key: API_KEY })
            const messageData = {
                from: "contratar@vidpay.es",
                to: ["vidpayteleco@gmail.com"],
                subject: "Contratacion",
                text: `
                    Nombre: ${formValues.name}\n
                    Apellidos: ${formValues.lastName}\n
                    DNI/NIE/CIF: ${formValues.identity}\n
                    Direccion: ${formValues.address}\n
                    Telefono: ${formValues.phone}\n
                    Email: ${formValues.email}\n
                    TarifaInternet: ${selectedTariff}\n
                    TarifaMovil: ${arrayMobileLines}\n
                    Precio: ${totalPrice}\n
                `,
                html: `
                    <h1>Nueva Contratacion</h1>
                    <h2>Datos del Cliente</h2>
                    <p>Nombre: ${formValues.name}</p>
                    <p>Apellidos: ${formValues.lastName}</p>
                    <p>DNI/NIE/CIF: ${formValues.identity}</p>
                    <p>Direccion: ${formValues.address}</p>
                    <p>Telefono: ${formValues.phone}</p>
                    <p>Email: ${formValues.email}</p>
                    <h2>Datos de la contratacion</h2>
                    <p>Tarifa Internet: ${selectedTariff}</p>
                    <p>Tarifas Moviles: ${arrayMobileLines}</p>
                    <p>Precio: ${totalPrice} €</p>
                `
            }
            client.messages.create(DOMAIN, messageData).then((res) => {
                setFormValues({ name: '', lastName: '', identity: '', address: '', phone: '', email: '' });
            }).catch((err) => {
                console.error(err)
            })
            setShowConfirmation(true);
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } catch (error) {
            throw error;
        }
    };



    return (
        <PageWrapper>
            {showConfirmation && (
                <ModalOverlay>
                    <ModalContent>
                        <ThemedText.MediumHeader mb="1rem">
                            <Trans>Data sent successfully</Trans>
                        </ThemedText.MediumHeader>
                        <ThemedText.MediumHeader mb="1rem">
                            <Trans>We will contact you soon</Trans>
                        </ThemedText.MediumHeader>
                        <EnvelopeIcon>
                            <FontAwesomeIcon icon={faEnvelope} fontSize="40px" />
                        </EnvelopeIcon>
                        <RowCenter mt="5rem">
                            <ButtonLink width="fit-content" to="/">
                                <Trans>Agreed</Trans>
                            </ButtonLink>
                        </RowCenter>
                    </ModalContent>
                </ModalOverlay>
            )}
            <ThemedText.TitleHeader color="secondary2" textAlign="center" my="2rem">
                <Trans>Contracting Data</Trans>
            </ThemedText.TitleHeader>
            <ThemedText.Body color="warning" textAlign="center">
                <Trans>Check that the data is correct</Trans>
            </ThemedText.Body>
            <ThemedText.SubTitleHeader color="secondary1" textAlign="center" my="1rem">
                <Trans>Personal Data</Trans>
            </ThemedText.SubTitleHeader>
            {Object.values(formValues).every(value => value === '') &&
                <ThemedText.Error error fontSize={16} textAlign="center">
                    <Trans>The contact form is missing information</Trans>
                </ThemedText.Error>
            }
            <FormContainer onSubmit={handleSubmit}>
                <AutoColumn gap="0.5rem">
                    <ThemedText.MediumHeader color="text2" textAlign="center">
                        {formValues.name} {formValues.lastName}
                    </ThemedText.MediumHeader>
                    <ThemedText.MediumHeader color="text2" textAlign="center">
                        {formValues.identity}
                    </ThemedText.MediumHeader>
                    <ThemedText.MediumHeader color="text2" textAlign="center">
                        {formValues.address}
                    </ThemedText.MediumHeader>
                    <ThemedText.MediumHeader color="text2" textAlign="center">
                        {formValues.phone}
                    </ThemedText.MediumHeader>
                    <ThemedText.MediumHeader color="text2" textAlign="center">
                        {formValues.email}
                    </ThemedText.MediumHeader>
                </AutoColumn>
                <ThemedText.SubTitleHeader color="secondary1" textAlign="center" my="1rem">
                    <Trans>Services Ordered</Trans>
                </ThemedText.SubTitleHeader>
                {totalPrice === 0 &&
                    <ThemedText.Error error fontSize={16} textAlign="center">
                        <Trans>Services not selected</Trans>
                    </ThemedText.Error>
                }
                <AutoColumn gap="0.5rem">
                    <ThemedText.MediumHeader color="text2" textAlign="center">
                        {selectedTariff}
                    </ThemedText.MediumHeader>
                    {arrayMobileLines.map((mobile, index) => {
                        const pack = currentLanguage === "es-ES" ? mobile.replace(/mobile/gi, "Móvil").replace(/unlimited/gi, "Ilimitado") : mobile
                        return (
                            <ThemedText.MediumHeader color="text2" textAlign="center" key={index}>
                                {pack}
                            </ThemedText.MediumHeader>
                        )

                    })}
                    {tv &&
                        <ThemedText.MediumHeader color="text2" textAlign="center">
                            TV
                        </ThemedText.MediumHeader>
                    }
                </AutoColumn>
                <ThemedText.TitleHeader color="green1" textAlign="center" my="1rem">
                    {totalPrice} €/<Trans>Month</Trans>
                </ThemedText.TitleHeader>
                <StyledRowRight>
                    <ButtonPrimary width="fit-content" onClick={() => setNextPhase(nextPhase - 1)}>
                        <Trans>Prev</Trans>
                    </ButtonPrimary>
                    <ButtonPrimary
                        type="submit"
                        width="fit-content"
                        disabled={totalPrice === 0 || Object.values(formValues).every(value => value === '')}
                    >
                        <Trans>Submit</Trans>
                    </ButtonPrimary>
                </StyledRowRight>
            </FormContainer>
        </PageWrapper >
    );
}
