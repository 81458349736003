import Slide from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ButtonExternalLink, ButtonLink } from "components/Button";
import { Trans } from "@lingui/macro";
import { ThemedText } from "theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract, faHouseSignal, faMobileScreen, faPhoneVolume, faPlus, faSignal, faTv } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { isMobile } from "utils/userAgent";
import { BlueCard } from "components/Card";


const Wrapper = styled(AutoColumn)`
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 2rem;
    margin-top: 10rem;
    margin-bottom: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        max-width: 800px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        max-width: 500px;
    `};
`;


const Divider = styled.div`
    border-top: 1px solid ${({ theme }) => theme.secondary1};
    margin-top: 1rem;
    margin-bottom: 1rem;
`;


const ResponsiveBox = styled.div`
    display flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        gap: 2rem;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        gap: 2rem;
    `};
`;

const ResponsivePacks = styled.div`
    display grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    place-items: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        grid-template-columns: 1fr;
        place-items: center normal;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-template-columns: 1fr;
        place-items: center normal;
    `};
`;

const Row = styled.div`
    display flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
`;

const StyledButtonLink = styled(ButtonLink)`
    background: ${({ theme }) => theme.secondary2};
    height: 10px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
    &:hover {
        color: ${({ theme }) => theme.primary2} !important;
        scale: 1.2;
    }
`;

export function MobileRates() {
    const phoneNumber = "+34648136914"
    return (
        <Wrapper>
            <Divider />
            <ResponsiveBox>
                <Row>
                    <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" />
                    <FontAwesomeIcon icon={faPlus} fontSize="20px" />
                    <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" />
                    <FontAwesomeIcon icon={faPlus} fontSize="20px" />
                    <FontAwesomeIcon icon={faTv} fontSize="40px" />
                </Row>
                <ThemedText.MediumHeader style={{ textTransform: 'uppercase' }} textAlign={isMobile ? "center" : "left"}>
                    <Trans>Calculate your best combination</Trans>
                </ThemedText.MediumHeader>
                <StyledButtonLink to="/calcular" width="fit-content">
                    <Trans>Calculate</Trans>
                </StyledButtonLink>
            </ResponsiveBox>
            <Divider />

            <ResponsivePacks>
                <Slide left>
                    <BlueCard>
                        <ThemedText.ExtraLargeHeader color="green1" textAlign="center">
                            9€/<Trans>Month</Trans>
                        </ThemedText.ExtraLargeHeader>
                        <Divider />
                        <AutoColumn gap="0.5rem">
                            <ThemedText.ExtraLargeHeader color="secondary1" textAlign="center">
                                35 GB
                            </ThemedText.ExtraLargeHeader>
                            <Row>
                                <FontAwesomeIcon icon={faMobileScreen} color="#CCEDFC" fontSize="20px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Unlimited calls</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                            <Row>
                                <FontAwesomeIcon icon={faFileContract} color="#CCEDFC" fontSize="20px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>No commitment</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                            <Row>
                                <FontAwesomeIcon icon={faSignal} color="#CCEDFC" fontSize="12px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Maximum speed</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                        </AutoColumn>
                        <Divider />
                        <ButtonLink to="/calcular/9990">
                            <Trans>Hire</Trans>
                        </ButtonLink>
                    </BlueCard>
                    <BlueCard>
                        <ThemedText.ExtraLargeHeader color="green1" textAlign="center">
                            12€/<Trans>Month</Trans>
                        </ThemedText.ExtraLargeHeader>
                        <Divider />
                        <AutoColumn gap="0.5rem">
                            <ThemedText.ExtraLargeHeader color="secondary1" textAlign="center">
                                70 GB
                            </ThemedText.ExtraLargeHeader>
                            <Row>
                                <FontAwesomeIcon icon={faMobileScreen} color="#CCEDFC" fontSize="20px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Unlimited calls</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                            <Row>
                                <FontAwesomeIcon icon={faFileContract} color="#CCEDFC" fontSize="20px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>No commitment</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                            <Row>
                                <FontAwesomeIcon icon={faSignal} color="#CCEDFC" fontSize="12px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Maximum speed</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                        </AutoColumn>
                        <Divider />
                        <ButtonLink to="/calcular/9991">
                            <Trans>Hire</Trans>
                        </ButtonLink>
                    </BlueCard>
                    <BlueCard>
                        <ThemedText.ExtraLargeHeader color="green1" textAlign="center">
                            14€/<Trans>Month</Trans>
                        </ThemedText.ExtraLargeHeader>
                        <Divider />
                        <AutoColumn gap="0.5rem">
                            <ThemedText.ExtraLargeHeader color="secondary1" textAlign="center">
                                140 GB
                            </ThemedText.ExtraLargeHeader>
                            <Row>
                                <FontAwesomeIcon icon={faMobileScreen} color="#CCEDFC" fontSize="20px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Unlimited calls</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                            <Row>
                                <FontAwesomeIcon icon={faFileContract} color="#CCEDFC" fontSize="20px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>No commitment</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                            <Row>
                                <FontAwesomeIcon icon={faSignal} color="#CCEDFC" fontSize="12px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Maximum speed</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                        </AutoColumn>
                        <Divider />
                        <ButtonLink to="/calcular/9992">
                            <Trans>Hire</Trans>
                        </ButtonLink>
                    </BlueCard>
                    <BlueCard>
                        <ThemedText.ExtraLargeHeader color="green1" textAlign="center">
                            20€/<Trans>Month</Trans>
                        </ThemedText.ExtraLargeHeader>
                        <Divider />
                        <AutoColumn gap="0.5rem">
                            <ThemedText.ExtraLargeHeader color="secondary1" textAlign="center">
                                Unlimited
                            </ThemedText.ExtraLargeHeader>
                            <Row>
                                <FontAwesomeIcon icon={faMobileScreen} color="#CCEDFC" fontSize="20px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Unlimited calls</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                            <Row>
                                <FontAwesomeIcon icon={faFileContract} color="#CCEDFC" fontSize="20px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>No commitment</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                            <Row>
                                <FontAwesomeIcon icon={faSignal} color="#CCEDFC" fontSize="12px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Maximum speed</Trans>
                                </ThemedText.MediumHeader>
                            </Row>
                        </AutoColumn>
                        <Divider />
                        <ButtonLink to="/calcular/9993">
                            <Trans>Hire</Trans>
                        </ButtonLink>
                    </BlueCard>
                </Slide>
            </ResponsivePacks>
            <ThemedText.MediumHeader textAlign="center" color="text2" mt="4rem">
                <Trans>Choose your best combination, or if you prefer, call us or contact us via WhatsApp</Trans>
            </ThemedText.MediumHeader>
            <Row style={{ justifyContent: 'center' }}>
                <ButtonExternalLink width="fit-content" href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                    <StyledIcon icon={faPhoneVolume} fontSize="40px" />
                </ButtonExternalLink>
                <ButtonExternalLink width="fit-content" href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                    <StyledIcon icon={faWhatsapp} fontSize="40px" />
                </ButtonExternalLink>
            </Row>
        </Wrapper >
    );
}
