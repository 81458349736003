import { faEnvelope, faPlus, faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { ButtonEmpty, ButtonLink, ButtonPrimary, ButtonSecondary } from 'components/Button';
import { AutoColumn } from 'components/Column';
import { RowCenter } from 'components/Row';
import { useActiveLocale } from 'hooks/useActiveLocale';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemedText } from 'theme';
import FormData from 'form-data';
import Mailgun from 'mailgun.js';
import { useNavigate } from 'react-router-dom';
import { EnvelopeIcon, ModalContent, ModalOverlay } from 'components/Modal';


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormContainer = styled.form`
  width: 100%;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const TextInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ResponsiveBox = styled.div`
    display grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        grid-template-columns: 1fr;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-template-columns: 1fr;
    `};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      grid-template-columns: 1fr;
  `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      grid-template-columns: 1fr;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 1rem;
    margin-bottom: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1rem;
    margin-bottom: 1rem;
  `};
`;

type FormValues = {
  name: string;
  lastName: string;
  identity: string;
};

type FormValuesGroup = {
  identityGroup: string[];
};

interface Box {
  id: number;
}

export function ContactForm() {
  const currentLanguage = useActiveLocale();
  const [conditions, setConditions] = useState(false)
  const [permission, setPermission] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const navigate = useNavigate()

  //Holder data
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    lastName: '',
    identity: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  //Recommended friends 
  const [formValuesGroup, setFormValuesGroup] = useState<FormValuesGroup>({
    identityGroup: [''],
  });

  const handleInputGroupChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    const newIdentities = [...formValuesGroup.identityGroup];
    newIdentities[index] = value;
    setFormValuesGroup({ ...formValuesGroup, identityGroup: newIdentities });
  };
  const [boxes, setBoxes] = useState<Box[]>([{ id: 0 }]);

  const handleAddBox = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (boxes.length < 15) {
      setBoxes([...boxes, { id: boxes.length }]);
      setFormValuesGroup((prevFormValues) => ({
        ...prevFormValues,
        identity: [...prevFormValues.identityGroup, ''],
      }));
    }
  };


  //Submit
  const API_KEY: any = process.env.REACT_APP_API_KEY
  const DOMAIN: any = process.env.REACT_APP_MAILGUN_DOMAIN
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const mailgun = new Mailgun(FormData)
      const client = mailgun.client({ username: 'api', key: API_KEY })
      const messageData = {
        from: "referidos@vidpay.es",
        to: ["vidpayteleco@gmail.com"],
        subject: "VidPayConnect: plan de referidos",
        text: `Nombre: ${formValues.name}\nApellidos: ${formValues.lastName}\nDNI/NIE/CIF: ${formValues.identity}\nReferidos: ${formValuesGroup.identityGroup}\n`,
        html: `<h1>VidPayConnect: plan de referidos</h1><p>Nombre: ${formValues.name}</p><p>Apellidos: ${formValues.lastName}</p><p>DNI/NIE/CIF: ${formValues.identity}</p><p>Referidos: ${formValuesGroup.identityGroup}</p>`
      }
      client.messages.create(DOMAIN, messageData).then((res) => {
        setFormValues({ name: '', lastName: '', identity: '' });
      }).catch((err) => {
        console.error(err)
      })
      setShowConfirmation(true);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Wrapper>
      {showConfirmation && (
        <ModalOverlay>
          <ModalContent>
            <ThemedText.MediumHeader mb="1rem">
              <Trans>Data sent successfully</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.MediumHeader mb="1rem">
              <Trans>We will contact you soon</Trans>
            </ThemedText.MediumHeader>
            <EnvelopeIcon>
              <FontAwesomeIcon icon={faEnvelope} fontSize="40px" />
            </EnvelopeIcon>
            <RowCenter mt="5rem">
              <ButtonLink width="fit-content" to="/">
                <Trans>Agreed</Trans>
              </ButtonLink>
            </RowCenter>
          </ModalContent>
        </ModalOverlay>
      )}
      <ThemedText.MediumHeader color="secondary3" mt="1rem">
        <Trans>Provide your information</Trans>
      </ThemedText.MediumHeader>
      <ThemedText.Body color="text2">
        <Trans>Please fill out this form with the details of the fiber+mobile contract holder</Trans>.
      </ThemedText.Body>
      <FormContainer onSubmit={handleSubmit}>
        <ResponsiveBox>
          <FormGroup>
            <label htmlFor="name"><Trans>Name</Trans>:</label>
            <TextInput
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
              placeholder="Juan"
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="lastName"><Trans>Last Name</Trans>:</label>
            <TextInput
              type="text"
              id="lastName"
              name="lastName"
              value={formValues.lastName}
              onChange={handleInputChange}
              placeholder="Hernandez"
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="identity">DNI/NIE/CIF:</label>
            <TextInput
              type="text"
              id="identity"
              name="identity"
              value={formValues.identity}
              onChange={handleInputChange}
              placeholder="xxxxxxxxA"
              required
            />
          </FormGroup>
        </ResponsiveBox>
        <AutoColumn gap="1rem">
          <ThemedText.MediumHeader color="secondary3" mt="1rem">
            <Trans>Provide the DNI/NIE/CIF of your friends</Trans>
          </ThemedText.MediumHeader>
          <ThemedText.Body color="text2">
            <Trans>Recommend Fiber+Mobile to a maximum of 15 friends and enjoy your free months</Trans>.
          </ThemedText.Body>
          <Container>
            {boxes.map((box, index) => (
              <FormGroup>
                <label htmlFor={`identity ${box}`}><Trans>DNI/NIE/CIF</Trans>:</label>
                <TextInput
                  key={index}
                  type="text"
                  id={`identity${index}`}
                  name={`identity${index}`}
                  value={formValuesGroup.identityGroup[index] || ''}
                  onChange={(e) => handleInputGroupChange(e, index)}
                  placeholder="xxxxxxxxA"
                  required
                />
              </FormGroup>
            ))}
          </Container>
        </AutoColumn>
        <Row>
          <ButtonEmpty type="button" width="10px" onClick={() => setConditions(!conditions)}>
            {conditions ? <FontAwesomeIcon icon={faSquareCheck} color='#CCEDFC' /> : <FontAwesomeIcon icon={faSquare} color='#54A9D0' />}
          </ButtonEmpty>
          <ThemedText.Body color="text2">
            <Trans>I have read and accept the terms and conditions</Trans>.
          </ThemedText.Body>
        </Row>
        <Row>
          <ButtonEmpty type="button" width="10px" onClick={() => setPermission(!permission)}>
            {permission ? <FontAwesomeIcon icon={faSquareCheck} color='#CCEDFC' /> : <FontAwesomeIcon icon={faSquare} color='#54A9D0' />}
          </ButtonEmpty>
          <ThemedText.Body color="text2">
            <Trans>I have informed my friends and they have authorized me to provide their DNI/NIE/CIF to participate in VidPayConnect</Trans>.
          </ThemedText.Body>
        </Row>
        <RowCenter mt="1rem" style={{ gap: '1rem' }}>
          <ButtonSecondary type="button" onClick={handleAddBox} width="155px" height="10px">
            <RowCenter style={{ gap: '0.5rem' }}>
              <FontAwesomeIcon icon={faPlus} color='#CCEDFC' />
              <ThemedText.SubHeader color="secondary1">
                <Trans>Add Friend</Trans>
              </ThemedText.SubHeader>
            </RowCenter>
          </ButtonSecondary>
          <ButtonPrimary type="submit" width="155px" height="10px" disabled={!conditions || !permission}>
            <Trans>Submit</Trans>
          </ButtonPrimary>
        </RowCenter>
      </FormContainer>
      <ThemedText.Small color="text2" mt="1rem" textAlign="justify">
        <Trans>By completing the form, you declare that the included data are accurate and truthful. The requested data are generally mandatory unless otherwise indicated. Failure to provide any mandatory data will result in the inability to address or manage your request. Basic information on Data Protection: The data provided will be processed by VidPay Telecommunications, as the data controller, solely for the purpose of addressing your contact request. The legal basis for this processing is the consent granted by voluntarily completing this form. The data will not be disclosed to any third party. You have the right to access, rectify, and delete your data, as well as other rights, as explained in the additional information. You can consult additional and detailed information on Data Protection in our Privacy Policy</Trans>
        {" "}
        <a href={`https://vidpay.es/documents/privacy-policy-${currentLanguage}.pdf`} style={{ textDecoration: 'none', color: '#90CAF9' }}>
          <Trans>here</Trans>
        </a>.
      </ThemedText.Small>
    </Wrapper>
  );
};