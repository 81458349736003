import styled from "styled-components/macro";
import { AutoColumn } from "../../components/Column";
import { Packs } from "./components/Packs";
import { FibreMobileInfo } from "./components/FibreMobileInfo";

const PageWrapper = styled(AutoColumn)`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`;

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.primary1};
`;


export function FibreMobile() {
  return (
    <PageWrapper>
      <Wrapper>
        <Packs />
      </Wrapper>
      <FibreMobileInfo />
    </PageWrapper>
  );
}
