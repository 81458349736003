import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import Row from "components/Row";
import { ButtonLinkMenu } from "components/Button";
import { useLocation } from "react-router-dom";


const HeaderLinks = styled(Row)`
  justify-self: center;
  width: fit-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
`
const StyledRow = styled(Row)`
  gap: 2rem;
`;

export default function Menu() {
  const { pathname } = useLocation();
  return (
    <HeaderLinks>
      <StyledRow>
        <ButtonLinkMenu id={`home-nav-link`} to="/" activecolor={pathname === "/" ? "true" : "false"}>
          <Trans>Home</Trans>
        </ButtonLinkMenu>


        <ButtonLinkMenu
          id={`fibremobile-nav-link`}
          to="/fibra-movil"
          activecolor={pathname === "/fibra-movil" ? "true" : "false"}>
          <Trans>Fibre and Mobile</Trans>
        </ButtonLinkMenu>


        <ButtonLinkMenu
          id={`fibre-nav-link`}
          to="/fibra"
          activecolor={pathname === "/fibra" ? "true" : "false"}>
          <Trans>Fibre</Trans>
        </ButtonLinkMenu>



        <ButtonLinkMenu
          id={`mobile-nav-link`}
          to="/movil"
          activecolor={pathname === "/movil" ? "true" : "false"}>
          <Trans>Mobile</Trans>
        </ButtonLinkMenu>


        <ButtonLinkMenu
          id={`tv-nav-link`}
          to="/tv"
          activecolor={pathname === "/tv" ? "true" : "false"}>
          <Trans>TV</Trans>
        </ButtonLinkMenu>

      </StyledRow>
    </HeaderLinks>
  );
}
