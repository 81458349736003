
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { Trans } from "@lingui/macro";
import { ContactForm } from "./components/ContactForm";
import { DarkCard } from "components/Card";
import { RowBetween, RowCenter } from "components/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faFileLines, faFileSignature, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import RecommendedImg from "assets/images/Recommended/friends-recommended.png"
import { AutoColumn } from "components/Column";

const PageWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 8rem;
  background:  ${({ theme }) => theme.primary1};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 7rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const Wrapper = styled.div`
  width: 1200px;
  margin: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`;

const Divider = styled.div`
  width: 600px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary1};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledRowBetween = styled(RowBetween)`
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const ResponsiveBox = styled.div`
    display grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2rem;
    gap: 1rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        grid-template-columns: 1fr;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-template-columns: 1fr;
    `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;



export function Recommended() {
    return (
        <PageWrapper>
            <Wrapper>
                <StyledRowBetween>
                    <AutoColumn>
                        <ThemedText.ExtraLargeHeader color="text2">
                            VidpayConnect
                        </ThemedText.ExtraLargeHeader>
                        <ThemedText.ExtraLargeText color="text2">
                            <Trans>Recommend fiber+mobile to a friend and get 1 month free</Trans>
                        </ThemedText.ExtraLargeText>
                    </AutoColumn>
                    <img src={RecommendedImg} alt="recommended-img" width="150px" />
                </StyledRowBetween>
                <RowCenter>
                    <DarkCard mb="2rem">
                        <ThemedText.LargeHeader color="secondary3" padding="1rem">
                            VidPay Connect: <Trans>Earn by Referring Friends</Trans>!
                        </ThemedText.LargeHeader>
                        <ThemedText.Body color="text2" textAlign="justify" padding="1rem">
                            <Trans>If you are a customer, invite your friends to hire and enjoy a free month of fibre.
                                To get one month free, you must have hired a fiber+mobile tariff, and your referrals must hire for a tariff equal to or higher than yours
                            </Trans>.
                        </ThemedText.Body>
                        <ThemedText.Body color="text2" textAlign="justify" padding="1rem">
                            <Trans>Invite up to a maximum of 15 friends to get free months on your billing</Trans>.
                        </ThemedText.Body>
                        <ThemedText.MediumHeader color="secondary3" padding="1rem">
                            <Trans>How does VidpayConnect work?</Trans>
                        </ThemedText.MediumHeader>
                        <ResponsiveBox>
                            <Column>
                                <FontAwesomeIcon icon={faPeopleGroup} fontSize="50px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Recommend Fiber+Mobile</Trans>
                                </ThemedText.MediumHeader>
                                <ThemedText.Body color="text2" textAlign="center">
                                    <Trans>Fill in your details and provide the ID number (DNI/NIF/CIF) of your friends</Trans>.
                                </ThemedText.Body>
                            </Column>
                            <Column>
                                <FontAwesomeIcon icon={faFileLines} fontSize="50px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Data verification</Trans>
                                </ThemedText.MediumHeader>
                                <ThemedText.Body color="text2" textAlign="center">
                                    <Trans>The data will be verified by our team to determine if the conditions are met</Trans>.
                                </ThemedText.Body>
                            </Column>
                            <Column>
                                <FontAwesomeIcon icon={faCalendarCheck} fontSize="50px" />
                                <ThemedText.MediumHeader color="secondary1" textAlign="center">
                                    <Trans>Free Month</Trans>
                                </ThemedText.MediumHeader>
                                <ThemedText.Body color="text2" textAlign="center">
                                    <Trans>Once everything is checked and verified, the customer will be contacted to inform them about the free month</Trans>.
                                </ThemedText.Body>
                            </Column>
                        </ResponsiveBox>
                        <Row>
                            <Divider />
                            <FontAwesomeIcon icon={faFileSignature} fontSize="40px" color="#CCEDFC" />
                            <Divider />
                        </Row>
                        <ContactForm />
                    </DarkCard>
                </RowCenter>
            </Wrapper>
        </PageWrapper >
    );
}
