import { Trans } from '@lingui/macro';
import { AutoColumn } from 'components/Column';
import styled from 'styled-components';
import { ThemedText } from 'theme';

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 2rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
      max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
      max-width: 500px;
  `};
`;

const PhoneNumber = styled.a`
  color: ${({ theme }) => theme.secondary1};
`;

export function ContactPhone() {

    return (
        <Wrapper>
            <AutoColumn gap="1rem">
                <ThemedText.LargeHeader color="text2" textAlign="center">
                    <Trans>Phone</Trans>
                </ThemedText.LargeHeader>
                <ThemedText.LargeHeader color="text2" textAlign="center">
                    <PhoneNumber href="tel:+34648136914">648 13 69 14</PhoneNumber>
                </ThemedText.LargeHeader>
                <ThemedText.Body color="text2" textAlign="center">
                    <Trans>Schedule: Monday to Friday from 10:00 AM to 8:00 PM</Trans>
                </ThemedText.Body>
                <ThemedText.Body color="text2" textAlign="center">
                    (<Trans>Spanish Peninsula Time</Trans>)
                </ThemedText.Body>
            </AutoColumn>
        </Wrapper>
    );
};