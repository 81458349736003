import Slide from "react-reveal";
import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import Bonus from "assets/images/Home/bonus.jpg"
import Landline from "assets/images/Home/landline-phone.jpg"
import Mobile from "assets/images/Home/mobile.jpg"
import Roaming from "assets/images/Home/roaming.jpg"
import { AutoColumn } from "components/Column";
import { ButtonLink } from "components/Button";
import { isMobile } from "utils/userAgent";

const PageWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    padding: 1rem;
    margin: auto;
    margin-top: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        max-width: 800px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        max-width: 350px;
    `};
`;

const ResponsiveBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 5rem;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
    `};
`;

const Image = styled.img`
    width: 400px;
    border-radius: 16px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 100%;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
    `};
`;


export function MobileInfo() {
    return (
        <PageWrapper>
            <Slide left>
                <ResponsiveBox>
                    <Image src={Mobile} alt="mobile-img" />
                    <AutoColumn style={{ gap: '1rem' }}>
                        <ThemedText.LargeHeader color="primary1">
                            <Trans>Unlimited Calls and GB on Your Mobile</Trans>
                        </ThemedText.LargeHeader>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>GB per month at maximum data speed and unlimited calls to national landlines and mobiles. Prorated and self-renewing rate. Subject to reasonable consumption clause included in contract terms. Once data is exhausted, browsing continues at 32 kbps</Trans>.
                        </ThemedText.Body>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>The unlimited calls bonus includes 5,000 minutes of usage and a maximum of 150 different destinations per month</Trans>.
                        </ThemedText.Body>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>Once these limits are exceeded, calls are charged at €0.20 for connection and €0.25 per minute. The user will be notified upon reaching 3,000 monthly minutes</Trans>.
                        </ThemedText.Body>
                    </AutoColumn>
                </ResponsiveBox>
            </Slide>
            <Slide right>
                <ResponsiveBox>
                    {isMobile && <Image src={Landline} alt="bonos-img" />}
                    <AutoColumn style={{ gap: '1rem' }}>
                        <ThemedText.LargeHeader color="primary1">
                            <Trans>Landline phone with SIM card</Trans>
                        </ThemedText.LargeHeader>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>You can contract our landline phone with the same rates as a mobile phone. Your landline number will be provided on the same SIM card. Contact us to learn more</Trans>.
                        </ThemedText.Body>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>National calls are free, and call establishments are as discussed in the previous section</Trans>.
                        </ThemedText.Body>
                        <ButtonLink to="/atencion-cliente" width="fit-content">
                            <Trans>Contact</Trans>
                        </ButtonLink>
                    </AutoColumn>
                    {!isMobile && <Image src={Landline} alt="bonos-img" />}
                </ResponsiveBox>
            </Slide>

            <Slide left>
                <ResponsiveBox>
                    <Image src={Roaming} alt="roaming-img" />
                    <AutoColumn style={{ gap: '1rem' }}>
                        <ThemedText.LargeHeader color="primary1">
                            <Trans>Roaming Data in Zone 1</Trans>
                        </ThemedText.LargeHeader>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>
                                This tariff includes, within the Fair Usage Policy (FUP) for roaming, a maximum usage of 14 GB of data at maximum speed in roaming in Zone 1 (European Economic Area).
                                Specific roaming conditions included in contract terms apply
                            </Trans>.
                        </ThemedText.Body>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>Special services, international usage (except those included in international bundles or tariffs), roaming, SMS, and directory services are subject to specific contract terms</Trans>.
                        </ThemedText.Body>
                    </AutoColumn>
                </ResponsiveBox>
            </Slide>
            <Slide right>
                <ResponsiveBox>
                    {isMobile && <Image src={Bonus} alt="bonos-img" />}
                    <AutoColumn style={{ gap: '1rem' }}>
                        <ThemedText.LargeHeader color="primary1">
                            <Trans>Contract Minute or GB Bundles to Expand Your Phone Service</Trans>
                        </ThemedText.LargeHeader>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>You can hire to optional data bundles of 500 MB, 1 GB, and 10 GB in case you run out of data and need to continue browsing at maximum speed</Trans>.
                        </ThemedText.Body>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>You can also hire to recurring international bundles of 100, 300, and 600 minutes</Trans>.
                        </ThemedText.Body>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>You can hire to the multiSIM service (up to 4 additional SIMs) and the fixed IP service on mobile</Trans>.
                        </ThemedText.Body>
                        <ButtonLink to="/atencion-cliente" width="fit-content">
                            <Trans>Contact</Trans>
                        </ButtonLink>
                    </AutoColumn>
                    {!isMobile && <Image src={Bonus} alt="bonos-img" />}
                </ResponsiveBox>
            </Slide>
        </PageWrapper>
    );
}
