import Slide from "react-reveal";
import { faCircleInfo, faHouseSignal, faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { ButtonLink } from "components/Button";
import { BlueCard } from "components/Card";
import { AutoColumn } from "components/Column";
import { RowBetween } from "components/Row";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { MouseoverTooltip } from "components/Tooltip";

const ResponsiveBox = styled.div`
    display grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        grid-template-columns: 1fr;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-template-columns: 1fr;
    `};
`;

const Divider = styled.div`
    border: 1px solid ${({ theme }) => theme.secondary1};
    margin-top: 1rem;
    margin-bottom: 1rem;
`;


const Row = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
`

interface RatesProps {
    location: number
}

export function Rates({ location }: RatesProps) {
    return (
        <ResponsiveBox>
            {location === 0 &&
                <Slide left>
                    <BlueCard height={320}>
                        <AutoColumn>
                            <RowBetween>
                                <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                                <Row>
                                    <ThemedText.LargeText color="#fff">
                                        <Trans>Fibre</Trans> 1 GB
                                    </ThemedText.LargeText>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>Installation and Router included</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                            </RowBetween>
                            <Divider />
                            <RowBetween>
                                <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                                <AutoColumn>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        70 GB
                                    </ThemedText.LargeText>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        <Trans>Unlimited calls</Trans>
                                    </ThemedText.LargeText>
                                </AutoColumn>
                            </RowBetween>
                            <Divider />
                            <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                                34€/<Trans>Month</Trans>
                            </ThemedText.ExtraLargeText>
                            <ButtonLink to="/calcular/0011">
                                <Trans>Hire</Trans>
                            </ButtonLink>
                        </AutoColumn>
                    </BlueCard>
                    <BlueCard height={320}>
                        <AutoColumn>
                            <RowBetween>
                                <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                                <Row>
                                    <ThemedText.LargeText color="#fff">
                                        <Trans>Fibre</Trans> 1 GB
                                    </ThemedText.LargeText>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>Installation and Router included</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                            </RowBetween>
                            <Divider />
                            <RowBetween>
                                <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                                <AutoColumn>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        140 GB
                                    </ThemedText.LargeText>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        <Trans>Unlimited calls</Trans>
                                    </ThemedText.LargeText>
                                </AutoColumn>
                            </RowBetween>
                            <Divider />
                            <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                                40€/<Trans>Month</Trans>
                            </ThemedText.ExtraLargeText>
                            <ButtonLink to="/calcular/0022">
                                <Trans>Hire</Trans>
                            </ButtonLink>
                        </AutoColumn>
                    </BlueCard>
                    <BlueCard height={320}>
                        <AutoColumn>
                            <RowBetween>
                                <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                                <Row>
                                    <ThemedText.LargeText color="#fff">
                                        <Trans>Fibre</Trans> 1 GB
                                    </ThemedText.LargeText>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>Installation and Router included</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                            </RowBetween>
                            <Divider />
                            <RowBetween>
                                <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                                <AutoColumn>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        <Trans>Unlimited Data</Trans>
                                    </ThemedText.LargeText>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        <Trans>Unlimited calls</Trans>
                                    </ThemedText.LargeText>
                                </AutoColumn>
                            </RowBetween>
                            <Divider />
                            <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                                45€/<Trans>Month</Trans>
                            </ThemedText.ExtraLargeText>
                            <ButtonLink to="/calcular/0033">
                                <Trans>Hire</Trans>
                            </ButtonLink>
                        </AutoColumn>
                    </BlueCard>
                </Slide>}

            {location === 1 &&
                <Slide left>
                    <BlueCard height={320}>
                        <AutoColumn>
                            <RowBetween>
                                <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                                <Row>
                                    <ThemedText.LargeText color="#fff">
                                        <Trans>Fibre</Trans> 300 MB
                                    </ThemedText.LargeText>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>Installation and Router included</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                            </RowBetween>
                            <Divider />
                            <RowBetween>
                                <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                                <AutoColumn>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        35 GB
                                    </ThemedText.LargeText>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        <Trans>Unlimited calls</Trans>
                                    </ThemedText.LargeText>
                                </AutoColumn>
                            </RowBetween>
                            <Divider />
                            <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                                30€/<Trans>Month</Trans>
                            </ThemedText.ExtraLargeText>
                            <ButtonLink to="/calcular/1000">
                                <Trans>Hire</Trans>
                            </ButtonLink>
                        </AutoColumn>
                    </BlueCard>
                    <BlueCard height={320}>
                        <AutoColumn>
                            <RowBetween>
                                <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                                <Row>
                                    <ThemedText.LargeText color="#fff">
                                        <Trans>Fibre</Trans> 600 MB
                                    </ThemedText.LargeText>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>Installation and Router included</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                            </RowBetween>
                            <Divider />
                            <RowBetween>
                                <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                                <AutoColumn>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        70 GB
                                    </ThemedText.LargeText>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        <Trans>Unlimited calls</Trans>
                                    </ThemedText.LargeText>
                                </AutoColumn>
                            </RowBetween>
                            <Divider />
                            <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                                40€/<Trans>Month</Trans>
                            </ThemedText.ExtraLargeText>
                            <ButtonLink to="/calcular/1051">
                                <Trans>Hire</Trans>
                            </ButtonLink>
                        </AutoColumn>
                    </BlueCard>
                    <BlueCard height={320}>
                        <AutoColumn>
                            <RowBetween>
                                <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                                <Row>
                                    <ThemedText.LargeText color="#fff">
                                        <Trans>Fibre</Trans> 600 MB
                                    </ThemedText.LargeText>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>Installation and Router included</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                            </RowBetween>
                            <Divider />
                            <RowBetween>
                                <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                                <AutoColumn>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        <Trans>Unlimited Data</Trans>
                                    </ThemedText.LargeText>
                                    <ThemedText.LargeText color="#fff" textAlign="right">
                                        <Trans>Unlimited calls</Trans>
                                    </ThemedText.LargeText>
                                </AutoColumn>
                            </RowBetween>
                            <Divider />
                            <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                                50€/<Trans>Month</Trans>
                            </ThemedText.ExtraLargeText>
                            <ButtonLink to="/calcular/1073">
                                <Trans>Hire</Trans>
                            </ButtonLink>
                        </AutoColumn>
                    </BlueCard>
                </Slide>}
        </ResponsiveBox>
    );
}
