import { Slide } from "react-reveal";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { ButtonLink, ButtonRadioChecked } from "components/Button";
import { BlueCard } from "components/Card";
import { AutoColumn } from "components/Column";
import { RowAround, RowCenter } from "components/Row";
import styled, { keyframes } from "styled-components/macro";
import { ThemedText } from "theme";
import Megaphone from "assets/images/megaphone.png"
import { useState } from "react";
import { isMobile } from "utils/userAgent";

const Section = styled.div`
    background: ${({ theme }) => theme.primary1};
    padding: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 1rem;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 1rem;
    `};
`;

const Box = styled.div`
    display: grid;
    place-items: center;
`;

const shakeAnimation = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

const VibratingImage = styled.img`
    animation: ${shakeAnimation} 0.5s infinite alternate;
    width: 200px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 150px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 150px;
    `};
`;


export function SpecialRate() {
    const [mobile, setMobile] = useState(true)
    const handleSetMobile = () => {
        setMobile(!mobile)
    }
    return (
        <Section>
            <Box>
                <ThemedText.ExtraLargeHeader color="secondary1" textAlign="center" my="2rem">
                    <Trans>Special Promotion</Trans>
                </ThemedText.ExtraLargeHeader>
                <Slide down>
                    <BlueCard width={isMobile ? "100%" : "600px"} height={350}>
                        <RowCenter mb="1rem">
                            <ThemedText.ExtraLargeHeader color="#fff">
                                <Trans>Fibre</Trans> 1 GB
                            </ThemedText.ExtraLargeHeader>
                            {mobile && <><FontAwesomeIcon icon={faPlus} fontSize={isMobile ? "24px" : "40px"} color="#ccedfc" />
                                <ThemedText.ExtraLargeHeader color="#fff">
                                    <Trans>Mobile</Trans> 35GB
                                </ThemedText.ExtraLargeHeader></>}
                        </RowCenter>
                        <ThemedText.Body color="yellow3" textAlign="center" my="2rem">
                            <Trans>Exclusive promotion for the province of Castellón</Trans>
                        </ThemedText.Body>
                        <RowAround>
                            <VibratingImage src={Megaphone} alt="megaphone-icon" />
                            <AutoColumn>
                                <ThemedText.ExtraLargeHeader textAlign="center" color="green1">
                                    {mobile ? "29€" : "25€"}/<Trans>Month</Trans>
                                </ThemedText.ExtraLargeHeader>
                                <ButtonRadioChecked active={mobile} onClick={handleSetMobile} my="1rem">
                                    <ThemedText.MediumHeader color="secondary1">
                                        <Trans>MOBILE</Trans>
                                    </ThemedText.MediumHeader>
                                </ButtonRadioChecked>
                                <ButtonLink to={!mobile ? "/calcular/010" : "/calcular/0000"}>
                                    <Trans>Hire</Trans>
                                </ButtonLink>
                            </AutoColumn>
                        </RowAround>
                    </BlueCard>
                </Slide>
            </Box>
        </Section>
    );
}
