
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { Trans } from "@lingui/macro";
import { DarkCard } from "components/Card";
import { RowCenter, RowRight } from "components/Row";
import { isMobile } from "utils/userAgent";
import { Calculate } from "./components/Calculate";
import { ContactForm } from "./components/ContactForm";
import { ButtonPrimary } from "components/Button";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { SubmitData } from "./components/SubmitData";

const PageWrapper = styled.div`
  width: 100%;
  margin-top: 8rem;
  background:  ${({ theme }) => theme.primary1};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 7rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const StyledRowRight = styled(RowRight)`
  gap: 1rem;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: center;
    align-items: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
    align-items: center;
  `};
`;


type FormValues = {
  name: string;
  lastName: string;
  identity: string;
  address: string;
  phone: string;
  email: string;
};

export function Calculator() {
  const { id } = useParams<{ id: string }>();
  //The combination of the url parameters indicates the selected package 
  //(digit 1 = location, digit 2 = type, digit 3 = selected tariff, digit 4 = mobile tariff)
  //Sometimes there may be no URL parameter
  const [location, setLocation] = useState(Number(id?.charAt(0) ?? 0))
  const [type, setType] = useState(Number(id?.charAt(1) ?? 0))
  const [select, setSelect] = useState(Number(id?.charAt(2) ?? -1))
  const [selectedTariff, setSelectedTariff] = useState("")
  const [selectedMobileLines, setSelectedMobileLines] = useState<{ name: string; price: number }[]>([]);
  const [tv, setTV] = useState<number>(Number(id?.charAt(4) ?? -1));
  const [nextPhase, setNextPhase] = useState(0);
  let selectMobile = [0, 0, 0, 0]
  if (id?.length === 4) {
    switch (Number(id?.charAt(3) ?? -1)) {
      case 0:
        selectMobile = [1, 0, 0, 0]
        break;
      case 1:
        selectMobile = [0, 1, 0, 0]
        break;
      case 2:
        selectMobile = [0, 0, 1, 0]
        break;
      case 3:
        selectMobile = [0, 0, 0, 1]
        break;

      default:
        break;
    }
  }
  const [linesArray, setLinesArray] = useState<number[]>(selectMobile);
  const [totalPrice, setTotalPrice] = useState(0);

  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    lastName: '',
    identity: '',
    address: '',
    phone: '',
    email: ''
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };




  return (
    <PageWrapper>
      <ThemedText.ExtraLargeHeader color="text2" textAlign="center" my="2rem">
        <Trans>Fiber and mobile combined, total connectivity at the best price</Trans>
      </ThemedText.ExtraLargeHeader>
      <RowCenter>
        <DarkCard mb="2rem" width={isMobile ? "100%" : "1200px"}>
          {nextPhase === 0 ?
            <Calculate
              setSelectedTariff={setSelectedTariff}
              location={location}
              setLocation={setLocation}
              type={type}
              setType={setType}
              select={select}
              setSelect={setSelect}
              selectedMobileLines={selectedMobileLines}
              setSelectedMobileLines={setSelectedMobileLines}
              tv={tv}
              setTV={setTV}
              linesArray={linesArray}
              setLinesArray={setLinesArray}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
            />

            : nextPhase === 1 ?
              <ContactForm
                formValues={formValues}
                handleInputChange={handleInputChange}
              />
              :
              <SubmitData
                selectedTariff={selectedTariff}
                mobileLines={linesArray}
                tv={tv === 1}
                totalPrice={totalPrice}
                formValues={formValues}
                setFormValues={setFormValues}
                nextPhase={nextPhase}
                setNextPhase={setNextPhase}
              />
          }

          <StyledRowRight>
            {nextPhase > 0 && nextPhase < 2 &&
              <ButtonPrimary width="fit-content" onClick={() => setNextPhase(nextPhase - 1)}>
                <Trans>Prev</Trans>
              </ButtonPrimary>}
            {nextPhase < 2 &&
              <ButtonPrimary width="fit-content" onClick={() => setNextPhase(nextPhase + 1)}>
                <Trans>Next</Trans>
              </ButtonPrimary>}
          </StyledRowRight>
        </DarkCard>
      </RowCenter>
    </PageWrapper>
  );
}
