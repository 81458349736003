import { Trans } from '@lingui/macro';
import React from 'react';
import styled from 'styled-components';
import { ThemedText } from 'theme';

const Wrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
      max-width: 500px;
  `};
`;

const FormContainer = styled.form`
  max-width: 600px;
  margin: auto;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const TextInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;


type FormValues = {
    name: string;
    lastName: string;
    identity: string;
    address: string;
    phone: string;
    email: string;
};

interface ContactFormProps {
    formValues: FormValues;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export function ContactForm({ formValues, handleInputChange }: ContactFormProps) {
    return (
        <Wrapper>
            <ThemedText.ExtraLargeHeader textAlign="center" my="2rem">
                <Trans>Fill in the following contact form</Trans>
            </ThemedText.ExtraLargeHeader>
            <FormContainer>
                <FormGroup>
                    <label htmlFor="name"><Trans>Name</Trans>:</label>
                    <TextInput
                        type="text"
                        id="name"
                        name="name"
                        value={formValues.name}
                        onChange={handleInputChange}
                        placeholder="Juan"
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="lastName"><Trans>Last Name</Trans>:</label>
                    <TextInput
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formValues.lastName}
                        onChange={handleInputChange}
                        placeholder="Hernandez"
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="identity"><Trans>DNI/NIE/CIF</Trans>:</label>
                    <TextInput
                        type="text"
                        id="identity"
                        name="identity"
                        value={formValues.identity}
                        onChange={handleInputChange}
                        placeholder="xxxxxxxxA"
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="address"><Trans>Address</Trans>:</label>
                    <TextInput
                        type="text"
                        id="address"
                        name="address"
                        value={formValues.address}
                        onChange={handleInputChange}
                        placeholder="Plaza De España 12 Local B, 12550"
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="phone"><Trans>Phone</Trans>:</label>
                    <TextInput
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formValues.phone}
                        onChange={handleInputChange}
                        placeholder="+34648136914"
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="email"><Trans>Email</Trans>:</label>
                    <TextInput
                        type="email"
                        id="email"
                        name="email"
                        value={formValues.email}
                        onChange={handleInputChange}
                        placeholder="vidpay@gmail.com"
                        required
                    />
                </FormGroup>
            </FormContainer>
        </Wrapper>
    );
};