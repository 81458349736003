import { Trans } from "@lingui/macro";
import Slide from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { Rates } from "./Rates/Rates";
import { BlueCard/* , GreyCard */ } from "components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseSignal, faLocationDot, /* faMapLocationDot, */ faMobileScreen, faPlus, faTv } from "@fortawesome/free-solid-svg-icons";
import { ButtonLink, ButtonSelect } from "components/Button";
import { SpecialRate } from "./Rates/SpecialRate";
import { useState } from "react";
import { isMobile } from "utils/userAgent";

const PageWrapper = styled(AutoColumn)`
    background: ${({ theme }) => theme.bg0};
`;

const Wrapper = styled(AutoColumn)`
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 1rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        max-width: 800px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        max-width: 500px;
    `};
`;

const ResponsiveBox = styled.div`
    display flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
    `};
`;

const Row = styled.div`
    display flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        gap: 0.5rem;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        gap: 0.5rem;
    `};
`;

const ButtonBox = styled.div`
    display flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        gap: 0.5rem;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        gap: 0.5rem;
    `};
`;

/* const ButtonCheck = styled(ButtonLink)`
    width: fit-content;
    background: transparent;
    color: ${({ theme }) => theme.primary5};
    border: 1px solid ${({ theme }) => theme.primary5};
`; */



export function PopularRates() {
    const [location, setLocation] = useState(0)
    return (
        <>
            {/* <GreyCard $borderRadius="0">
                <ResponsiveBox style={{ justifyContent: 'center' }}>
                    <Row>
                        <FontAwesomeIcon icon={faMapLocationDot} fontSize="40px" color="#324a5e" />
                        <ThemedText.LargeHeader color="primary5">
                            <Trans>Check your fibre coverage</Trans>
                        </ThemedText.LargeHeader>
                    </Row>
                    <ButtonCheck to="/">
                        <Trans>Check</Trans>
                    </ButtonCheck>
                </ResponsiveBox>
            </GreyCard> */}
            <SpecialRate />
            <PageWrapper>
                <Wrapper>
                    <ThemedText.ExtraLargeHeader color="primary1" textAlign="center" my="2rem">
                        <Trans>Our most popular rates</Trans>
                    </ThemedText.ExtraLargeHeader>
                    <ButtonBox>
                        <ButtonSelect width={isMobile ? "220px" : "250px"} onClick={() => setLocation(0)} active={location === 0}>
                            {location === 0 && <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '0.5rem' }} />}
                            <Trans>Province of Castellon</Trans>
                        </ButtonSelect>
                        <ButtonSelect width={isMobile ? "220px" : "250px"} onClick={() => setLocation(1)} active={location === 1}>
                            {location === 1 && <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '0.5rem' }} />}
                            <Trans>Rest of Spain</Trans>
                        </ButtonSelect>
                    </ButtonBox>
                    <Rates location={location} />
                    <Slide up>
                        <BlueCard mt="2rem">
                            <ResponsiveBox>
                                <Row>
                                    <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                                    <FontAwesomeIcon icon={faPlus} fontSize="20px" color="#ccedfc" />
                                    <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                                    <FontAwesomeIcon icon={faPlus} fontSize="20px" color="#ccedfc" />
                                    <FontAwesomeIcon icon={faTv} fontSize="40px" color="#ccedfc" />
                                </Row>
                                <ThemedText.MediumHeader color="text2" textAlign="center">
                                    <Trans>Calculate your best combination</Trans>
                                </ThemedText.MediumHeader>
                                <ButtonLink to="/calcular" width="fit-content">
                                    <Trans>Calculate</Trans>
                                </ButtonLink>
                            </ResponsiveBox>
                        </BlueCard>
                    </Slide>
                </Wrapper>
            </PageWrapper>
        </>
    );
}
