import styled, { keyframes } from "styled-components/macro";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #33445d;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.secondary2};
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
`;


const animateEnvelope = keyframes`
  0% {
    opacity: 1;
    transform: translateX(-100%);
  }
  50% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
`;

export const EnvelopeIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${animateEnvelope} 3s linear infinite;
  opacity: 0;
`;