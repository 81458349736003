import styled from "styled-components/macro";
import { AutoColumn } from "../../components/Column";
import { MobileInfo } from "./components/MobileInfo";
import { MobileRates } from "./components/MobileRates";

const PageWrapper = styled(AutoColumn)`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`;

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.primary1};
`;


export function Mobile() {
    return (
        <PageWrapper>
            <Wrapper>
                <MobileRates />
            </Wrapper>
            <MobileInfo />
        </PageWrapper>
    );
}
