import styled from "styled-components/macro";
import { AutoColumn } from "../../components/Column";
import { Header } from "./components/Header";
import { PopularRates } from "./components/PopularRates";
import { Info } from "./components/Info";

const PageWrapper = styled(AutoColumn)`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`;

export function Home() {
  return (
    <PageWrapper>
      <Header />
      <PopularRates />
      <Info />
    </PageWrapper>
  );
}
