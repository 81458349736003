import Slide from "react-reveal";
import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import Fibre from "assets/images/Home/fibre.jpg"
import { AutoColumn } from "components/Column";

const PageWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    padding: 1rem;
    margin: auto;
    margin-top: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        max-width: 800px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        max-width: 350px;
    `};
`;

const ResponsiveBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 5rem;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
    `};
`;

const Image = styled.img`
    width: 400px;
    border-radius: 16px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 100%;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
    `};
`;


export function FibreInfo() {
    return (
        <PageWrapper>
            <Slide down>
                <ResponsiveBox>
                    <Image src={Fibre} alt="fibre-img" />
                    <AutoColumn style={{ gap: '1rem' }}>
                        <ThemedText.LargeHeader color="primary1">
                            <Trans>Choose our seamless fiber optic connection</Trans>
                        </ThemedText.LargeHeader>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>With Vidpay Telecom, you can surf at maximum speed and enjoy seamless internet connectivity at a good price</Trans>.
                        </ThemedText.Body>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>Choose from our fiber optic options with speeds of 100 MB, 300 MB, 600 MB, and 1 GB depending on your coverage</Trans>.
                        </ThemedText.Body>
                        <ThemedText.Body color="text1" textAlign="justify">
                            <Trans>In addition, the fibre optic service includes the installation and the router for free</Trans>.
                        </ThemedText.Body>
                    </AutoColumn>
                </ResponsiveBox>
            </Slide>
        </PageWrapper>
    );
}
