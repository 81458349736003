import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { AutoColumn } from "components/Column";
import { stack as StackMenu } from "react-burger-menu";
import { useDarkModeManager } from "state/user/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faWifi,
    faHouse,
    faMobile,
    faHouseSignal,
    faTv,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Logo from "assets/logo-x.png";
import { ButtonLinkMenu } from "components/Button";
import { useLocation } from "react-router-dom";

const Header = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.text3};
  }
`;

export default function MenuMobile() {
    const [isDark] = useDarkModeManager();
    const { pathname } = useLocation();
    let styles = {
        bmBurgerButton: {
            position: "fixed",
            width: "30px",
            height: "20px",
            top: "2.4rem",
            right: "1rem",
        },
        bmBurgerBars: {
            background: "#5B83B8",
        },
        bmBurgerBarsHover: {
            background: "#a90000",
        },
        bmCrossButton: {
            height: "24px",
            width: "24px",
            marginTop: "4px",
        },
        bmCross: {
            background: "#bdc3c7",
        },
        bmMenuWrap: {
            top: 0,
            left: 0,
        },
        bmMenu: {
            background: "#f8f8ff",
            fontSize: "1.15em",
        },
        bmMorphShape: {
            fill: "#373a47",
        },
        bmItemList: {
            color: isDark ? "#FFFFFF" : "#FFFFFF",
        },
        bmOverlay: {
            background: "rgba(0, 0, 0, 0.3)",
            top: 0,
        },
    };

    const [open, setOpen] = useState(false);
    const handleSetOpen = () => {
        setOpen(true);
    };
    const handleSetClose = () => {
        setOpen(false);
    };

    return (
        <StackMenu
            styles={styles}
            isOpen={open}
            onOpen={handleSetOpen}
            onClose={handleSetClose}
        >
            <Header>
                <img src={Logo} alt="logo" width="180px" />
            </Header>
            <Container>
                <AutoColumn gap="1rem" style={{ padding: '2rem' }}>
                    <Row>
                        <ButtonLinkMenu id={`home-nav-link`} to="/" onClick={() => {
                            handleSetClose();
                        }} activecolor={pathname === "/" ? "true" : "false"}>
                            <Row>
                                <FontAwesomeIcon icon={faHouse} width="24px" />
                                <Trans>Home</Trans>
                            </Row>
                        </ButtonLinkMenu>
                    </Row>
                    <Row>
                        <ButtonLinkMenu
                            id={`fibremobile-nav-link`}
                            to="/fibra-movil"
                            onClick={() => {
                                handleSetClose();
                            }} activecolor={pathname === "/fibra-movil" ? "true" : "false"}>
                            <Row>
                                <FontAwesomeIcon icon={faHouseSignal} width="24px" />
                                <Trans>Fibre and Mobile</Trans>
                            </Row>
                        </ButtonLinkMenu>
                    </Row>
                    <Row>
                        <ButtonLinkMenu
                            id={`fibre-nav-link`}
                            to="/fibra"
                            onClick={() => {
                                handleSetClose();
                            }} activecolor={pathname === "/fibra" ? "true" : "false"}>
                            <Row>
                                <FontAwesomeIcon icon={faWifi} width="24px" />
                                <Trans>Fibre</Trans>
                            </Row>
                        </ButtonLinkMenu>
                    </Row>
                    <Row>
                        <ButtonLinkMenu
                            id={`mobile-nav-link`}
                            to="/movil"
                            onClick={() => {
                                handleSetClose();
                            }} activecolor={pathname === "/movil" ? "true" : "false"}>
                            <Row>
                                <FontAwesomeIcon icon={faMobile} width="24px" />
                                <Trans>Mobile</Trans>
                            </Row>
                        </ButtonLinkMenu>
                    </Row>
                    <Row>
                        <ButtonLinkMenu
                            id={`tv-nav-link`}
                            to="/tv"
                            onClick={() => {
                                handleSetClose();
                            }} activecolor={pathname === "/tv" ? "true" : "false"}>
                            <Row>
                                <FontAwesomeIcon icon={faTv} width="24px" />
                                <Trans>TV</Trans>
                            </Row>
                        </ButtonLinkMenu>
                    </Row>
                    <Row>
                        <ButtonLinkMenu
                            id={`service-nav-link`}
                            to="/atencion-cliente"
                            onClick={() => {
                                handleSetClose();
                            }} activecolor={pathname === "/atencion-cliente" ? "true" : "false"}
                        >
                            <Row>
                                <FontAwesomeIcon icon={faUser} width="24px" />
                                <Trans>Customer Service</Trans>
                            </Row>
                        </ButtonLinkMenu>
                    </Row>

                </AutoColumn>
            </Container >
        </StackMenu >
    );
}
