import { Trans } from '@lingui/macro';
import { ButtonLink, ButtonPrimary } from 'components/Button';
import { RowCenter } from 'components/Row';
import { useActiveLocale } from 'hooks/useActiveLocale';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemedText } from 'theme';
import FormData from 'form-data';
import Mailgun from 'mailgun.js';
import { EnvelopeIcon, ModalContent, ModalOverlay } from 'components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 2rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
      max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
      max-width: 500px;
  `};
`;

const FormContainer = styled.form`
  max-width: 1200px;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const TextInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

type FormValues = {
  name: string;
  phone: string;
  email: string;
  message: string;
};

export function ContactForm() {
  const currentLanguage = useActiveLocale();
  const navigate = useNavigate()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const API_KEY: any = process.env.REACT_APP_API_KEY
  const DOMAIN: any = process.env.REACT_APP_MAILGUN_DOMAIN

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const mailgun = new Mailgun(FormData)
      const client = mailgun.client({ username: 'api', key: API_KEY })
      const messageData = {
        from: "soporte@vidpay.es",
        to: ["vidpayteleco@gmail.com"],
        subject: "Atencion al cliente",
        text: `Nombre: ${formValues.name}\nTelefono: ${formValues.phone}\nEmail: ${formValues.email}\nMensaje: ${formValues.message}`,
        html: `<h1>Atencion al cliente</h1><p>Nombre: ${formValues.name}</p><p>Telefono: ${formValues.phone}</p><p>Email: ${formValues.email}</p><p>Mensaje: ${formValues.message}</p>`
      }
      client.messages.create(DOMAIN, messageData).then((res) => {
        setFormValues({ name: '', phone: '', email: '', message: '' });
      }).catch((err) => {
        console.error(err)
      })
      setShowConfirmation(true);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Wrapper>
      {showConfirmation && (
        <ModalOverlay>
          <ModalContent>
            <ThemedText.MediumHeader mb="1rem">
              <Trans>Data sent successfully</Trans>
            </ThemedText.MediumHeader>
            <ThemedText.MediumHeader mb="1rem">
              <Trans>We will contact you soon</Trans>
            </ThemedText.MediumHeader>
            <EnvelopeIcon>
              <FontAwesomeIcon icon={faEnvelope} fontSize="40px" />
            </EnvelopeIcon>
            <RowCenter mt="5rem">
              <ButtonLink width="fit-content" to="/">
                <Trans>Agreed</Trans>
              </ButtonLink>
            </RowCenter>
          </ModalContent>
        </ModalOverlay>
      )}
      <ThemedText.LargeHeader color="text2" textAlign="center">
        <Trans>Contact Form</Trans>
      </ThemedText.LargeHeader>
      <FormContainer onSubmit={handleSubmit}>
        <FormGroup>
          <label htmlFor="name"><Trans>Name</Trans>:</label>
          <TextInput
            type="text"
            id="name"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            placeholder="Juan"
            required
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="phone"><Trans>Phone</Trans>:</label>
          <TextInput
            type="tel"
            id="phone"
            name="phone"
            value={formValues.phone}
            onChange={handleInputChange}
            placeholder="Hernandez"
            required
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="email">Email:</label>
          <TextInput
            type="email"
            id="email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            placeholder="vidpay@gmail.com"
            required
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="message"><Trans>Message</Trans>:</label>
          <TextArea
            id="message"
            name="message"
            value={formValues.message}
            onChange={handleInputChange}
            placeholder=""
            required
          />
        </FormGroup>
        <RowCenter>
          <ButtonPrimary
            type="submit"
            width="fit-content"
            height="10px"
          >
            <Trans>Submit</Trans>
          </ButtonPrimary>
        </RowCenter>
      </FormContainer>
      <ThemedText.Small color="text2" mt="1rem" textAlign="justify">
        <Trans>By completing the form, you declare that the included data are accurate and truthful. The requested data are generally mandatory unless otherwise indicated. Failure to provide any mandatory data will result in the inability to address or manage your request. Basic information on Data Protection: The data provided will be processed by VidPay Telecommunications, as the data controller, solely for the purpose of addressing your contact request. The legal basis for this processing is the consent granted by voluntarily completing this form. The data will not be disclosed to any third party. You have the right to access, rectify, and delete your data, as well as other rights, as explained in the additional information. You can consult additional and detailed information on Data Protection in our Privacy Policy</Trans>
        {" "}
        <a href={`https://vidpay.es/documents/privacy-policy-${currentLanguage}.pdf`} style={{ textDecoration: 'none', color: '#90CAF9' }}>
          <Trans>here</Trans>
        </a>.
      </ThemedText.Small>

    </Wrapper>
  );
};