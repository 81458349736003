import Slide from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ButtonExternalLink, ButtonLink, ButtonSelect } from "components/Button";
import { Trans } from "@lingui/macro";
import { ThemedText } from "theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faHouseSignal, faLocationDot, /* faMapLocationDot, */ faMobileScreen, faPhoneVolume, faPlus, faTv } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { isMobile } from "utils/userAgent";
import { BlueCard } from "components/Card";
import { MouseoverTooltip } from "components/Tooltip";
import { useState } from "react";


const Wrapper = styled(AutoColumn)`
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 2rem;
    margin-top: 10rem;
    margin-bottom: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        max-width: 800px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        max-width: 500px;
    `};
`;


const Divider = styled.div`
    border-top: 1px solid ${({ theme }) => theme.secondary1};
    margin-top: 1rem;
    margin-bottom: 1rem;
`;


const ResponsiveBox = styled.div`
    display flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        gap: 2rem;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        gap: 2rem;
    `};
`;

const ResponsivePacks = styled.div<{ loc: number }>`
    display grid;
    grid-template-columns: ${({ loc }) => loc === 0 ? "1fr" : "1fr 1fr 1fr"};
    place-items: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        grid-template-columns: 1fr;
        place-items: center normal;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-template-columns: 1fr;
        place-items: center normal;
    `};
`;

const Row = styled.div`
    display flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

/* const Column = styled.div`
    display flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledButtonLink = styled(ButtonLink)`
    background: ${({ theme }) => theme.secondary2};
    height: 10px;
`; */

const StyledIcon = styled(FontAwesomeIcon)`
    &:hover {
        color: ${({ theme }) => theme.primary2} !important;
        scale: 1.2;
    }
`;

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
`


const ButtonBox = styled.div`
    display flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        gap: 0.5rem;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        gap: 0.5rem;
    `};
`;



export function FibreRates() {
    const [location, setLocation] = useState(0)
    const phoneNumber = "+34648136914"
    return (
        <Wrapper>
            {/* <Divider />
            <ResponsiveBox>
                <FontAwesomeIcon icon={faMapLocationDot} fontSize="80px" />
                <AutoColumn>
                    <ThemedText.MediumHeader style={{ textTransform: 'uppercase' }} textAlign={isMobile ? "center" : "left"}>
                        <Trans>Check your coverage and ensure</Trans>
                    </ThemedText.MediumHeader>
                    <ThemedText.MediumHeader style={{ textTransform: 'uppercase' }} textAlign={isMobile ? "center" : "left"}>
                        <Trans>you're getting the best service</Trans>.
                    </ThemedText.MediumHeader>
                </AutoColumn>
                <Column>
                    <StyledButtonLink to="/" width="fit-content">
                        <Trans>Check</Trans>
                    </StyledButtonLink>
                </Column>
            </ResponsiveBox>
            <Divider /> */}
            <ButtonBox>
                <ButtonSelect width="250px" onClick={() => setLocation(0)} active={location === 0}>
                    {location === 0 && <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '0.5rem' }} />}
                    <Trans>Province of Castellon</Trans>
                </ButtonSelect>
                <ButtonSelect width="250px" onClick={() => setLocation(1)} active={location === 1}>
                    {location === 1 && <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '0.5rem' }} />}
                    <Trans>Rest of Spain</Trans>
                </ButtonSelect>
            </ButtonBox>

            <ResponsivePacks loc={location}>
                {location === 0 ?
                    <Slide left>
                        <BlueCard height={300} width={isMobile ? "100%" : "350px"}>
                            <AutoColumn>
                                <ThemedText.ExtraLargeHeader color="#fff" textAlign="center">
                                    <Trans>Fibre</Trans> 1 GB
                                </ThemedText.ExtraLargeHeader>
                                <Divider />
                                <Row style={{ gap: '0.2rem' }}>
                                    <ThemedText.MediumHeader color="#fff">
                                        <Trans>Installation and Router included</Trans>
                                    </ThemedText.MediumHeader>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>Installation and Router included</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                                <Divider />
                                <ThemedText.ExtraLargeHeader color="green1" textAlign="center" mb="1rem">
                                    25€/<Trans>Month</Trans>
                                </ThemedText.ExtraLargeHeader>
                                <ButtonLink to="/calcular/010">
                                    <Trans>Hire</Trans>
                                </ButtonLink>
                            </AutoColumn>
                        </BlueCard>
                    </Slide>
                    :
                    <Slide left>
                        <BlueCard height={300} width={isMobile ? "100%" : "350px"}>
                            <AutoColumn>
                                <ThemedText.ExtraLargeHeader color="#fff" textAlign="center">
                                    <Trans>Fibre</Trans> 100 MB
                                </ThemedText.ExtraLargeHeader>
                                <Divider />
                                <Row style={{ gap: '0.2rem' }}>
                                    <ThemedText.MediumHeader color="#fff">
                                        <Trans>Installation and Router included</Trans>
                                    </ThemedText.MediumHeader>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                                <Divider />
                                <ThemedText.ExtraLargeHeader color="green1" textAlign="center" mb="1rem">
                                    20€/<Trans>Month</Trans>
                                </ThemedText.ExtraLargeHeader>
                                <ButtonLink to="/calcular/110">
                                    <Trans>Hire</Trans>
                                </ButtonLink>
                            </AutoColumn>
                        </BlueCard>
                        <BlueCard height={300} width={isMobile ? "100%" : "350px"}>
                            <AutoColumn>
                                <ThemedText.ExtraLargeHeader color="#fff" textAlign="center">
                                    <Trans>Fibre</Trans> 300 MB
                                </ThemedText.ExtraLargeHeader>
                                <Divider />
                                <Row style={{ gap: '0.2rem' }}>
                                    <ThemedText.MediumHeader color="#fff">
                                        <Trans>Installation and Router included</Trans>
                                    </ThemedText.MediumHeader>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                                <Divider />
                                <ThemedText.ExtraLargeHeader color="green1" textAlign="center" mb="1rem">
                                    24€/<Trans>Month</Trans>
                                </ThemedText.ExtraLargeHeader>
                                <ButtonLink to="/calcular/111">
                                    <Trans>Hire</Trans>
                                </ButtonLink>
                            </AutoColumn>
                        </BlueCard>
                        <BlueCard height={300} width={isMobile ? "100%" : "350px"}>
                            <AutoColumn>
                                <ThemedText.ExtraLargeHeader color="#fff" textAlign="center">
                                    <Trans>Fibre</Trans> 600 MB
                                </ThemedText.ExtraLargeHeader>
                                <Divider />
                                <Row style={{ gap: '0.2rem' }}>
                                    <ThemedText.MediumHeader color="#fff">
                                        <Trans>Installation and Router included</Trans>
                                    </ThemedText.MediumHeader>
                                    <MouseoverTooltip
                                        text={
                                            <AutoColumn gap="0.2rem">
                                                <ThemedText.SubHeader>
                                                    - <Trans>12 months of commitment</Trans>
                                                </ThemedText.SubHeader>
                                                <ThemedText.SubHeader>
                                                    - <Trans>Penalty of 150€</Trans>
                                                </ThemedText.SubHeader>
                                            </AutoColumn>
                                        }
                                    >
                                        <WrapperIcon>
                                            <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                        </WrapperIcon>
                                    </MouseoverTooltip>
                                </Row>
                                <Divider />
                                <ThemedText.ExtraLargeHeader color="green1" textAlign="center" mb="1rem">
                                    28€/<Trans>Month</Trans>
                                </ThemedText.ExtraLargeHeader>
                                <ButtonLink to="/calcular/112">
                                    <Trans>Hire</Trans>
                                </ButtonLink>
                            </AutoColumn>
                        </BlueCard>
                    </Slide>
                }
            </ResponsivePacks>
            <Slide up>
                <BlueCard mt="2rem">
                    <ResponsiveBox>
                        <Row>
                            <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                            <FontAwesomeIcon icon={faPlus} fontSize="20px" color="#ccedfc" />
                            <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                            <FontAwesomeIcon icon={faPlus} fontSize="20px" color="#ccedfc" />
                            <FontAwesomeIcon icon={faTv} fontSize="40px" color="#ccedfc" />
                        </Row>
                        <ThemedText.MediumHeader color="text2" textAlign="center">
                            <Trans>Calculate your best combination</Trans>
                        </ThemedText.MediumHeader>
                        <ButtonLink to="/calcular" width="fit-content">
                            <Trans>Calculate</Trans>
                        </ButtonLink>
                    </ResponsiveBox>
                </BlueCard>
            </Slide>
            <ThemedText.MediumHeader textAlign="center" color="text2" mt="4rem">
                <Trans>Choose your best combination, or if you prefer, call us or contact us via WhatsApp</Trans>
            </ThemedText.MediumHeader>
            <Row>
                <ButtonExternalLink width="fit-content" href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                    <StyledIcon icon={faPhoneVolume} fontSize="40px" />
                </ButtonExternalLink>
                <ButtonExternalLink width="fit-content" href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                    <StyledIcon icon={faWhatsapp} fontSize="40px" />
                </ButtonExternalLink>
            </Row>
        </Wrapper >
    );
}
