import Slide from "react-reveal";
import styled from "styled-components/macro";
import HeaderImg from "assets/images/Home/header.jpg"
import { ThemedText } from "theme";
import { Trans } from "@lingui/macro";
import { ButtonLink } from "components/Button";
import { RowCenter } from "components/Row";
import { isMobile } from "utils/userAgent";

const PageWrapper = styled.header`
  width: 100%;
  position: relative;
  margin-top: 8rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 7rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const Body = styled(ThemedText.Title)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `};
`;


export function Header() {
  return (
    <PageWrapper>
      <Image src={HeaderImg} alt="header-img" />
      <Body>
        <Slide left>
          <Trans>Connect and enjoy with VidPay Telecom</Trans>
          <RowCenter mt={isMobile ? "1rem" : "2rem"}>
            <ButtonLink width="fit-content" to="/atencion-cliente">
              <ThemedText.LargeHeader color="#fff">
                <Trans>Contact</Trans>
              </ThemedText.LargeHeader>
            </ButtonLink>
          </RowCenter>
        </Slide>
      </Body>
    </PageWrapper>
  );
}
