
import { faArrowRight, faCheck, faCircleMinus, faCirclePlus, faLocationDot, faMagnifyingGlassPlus, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { ButtonChange, ButtonEmpty, ButtonOption, ButtonSecondary, ButtonSelect } from "components/Button";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { AutoColumn } from "components/Column";
import { RowBetween, RowCenter, RowRight } from "components/Row";

import CsFibre from "./json/Castellon/cs-fibre.json"
import CsPacks from "./json/Castellon/cs-packs.json"
import SpFibre from "./json/Spain/sp-fibre.json"
import SpPacks from "./json/Spain/sp-packs.json"
import MobilePacks from "./json/mobile.json"
import { DarkBlueCard } from "components/Card";
import { isMobile } from "utils/userAgent";
import { useActiveLocale } from "hooks/useActiveLocale";


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
`;

const ButtonBox = styled.div`
    display flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        gap: 0.5rem;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        gap: 0.5rem;
    `};
`;

const ButtonWrapper = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-bottom: 1rem;
    background: ${({ theme }) => theme.primary4};
    border-radius: 24px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
    }
`;

const StyledRowBetween = styled.div`
    display: grid;
    grid-template-columns: 1.2fr 1.6fr 0.5fr 0fr;
    background:  ${({ theme }) => theme.primary4};
    color: ${({ theme }) => theme.text2};
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
    transition: box-shadow 0.3s ease;
    padding: 1rem;
    &:focus {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
        background:  ${({ theme }) => theme.primary3};
        border: 1px solid  ${({ theme }) => theme.secondary1};
    }
    &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
        background:  ${({ theme }) => theme.primary3};
        border: 1px solid  ${({ theme }) => theme.secondary1};
    }
    &:active {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
        background:  ${({ theme }) => theme.primary3};
        border: 1px solid  ${({ theme }) => theme.secondary1};
    }

    ${({ theme }) => theme.mediaWidth.upToMedium`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    `};
    
`;

const ButtonLines = styled(ButtonEmpty)`
    padding: 0;
    width: fit-content;
`;

const StyledRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: fit-content;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: fit-content;
    `};
`;

const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledDarkBlueCard = styled(DarkBlueCard) <{ expanded: boolean }>`
    width: 300px;
    ${({ theme, expanded }) => theme.mediaWidth.upToMedium`
        width: 100%;
        position: fixed;
        z-index: 99;
        bottom: 0;
        left: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: ${expanded ? "0" : "16px"};
        border-top-right-radius: ${expanded ? "0" : "16px"};
    `};

    ${({ theme, expanded }) => theme.mediaWidth.upToSmall`
        width: 100%;
        position: fixed;
        z-index: 99;
        bottom: 0;
        left: 0;
        border-top-left-radius: ${expanded ? "0" : "16px"};
        border-top-right-radius: ${expanded ? "0" : "16px"};
    `};
`;

const StyledDetailsContent = styled.div<{ expanded: boolean }>`
    width: 100%;
    display: ${({ expanded }) => (expanded ? 'block' : 'none')};
    background-color: ${({ theme }) => theme.primary3};
    color: white;
    padding: 1rem;
    position: absolute;
    bottom: 100%;
    left: 0;
`;

const ResponsiveBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: auto;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: row;
        justify-content: space-between;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: row;
        justify-content: space-between;
    `};
`;

const TextThrought = styled(ThemedText.MediumHeader)`
    text-decoration: line-through;
    color: red;
`;

interface CalculateProps {
    setSelectedTariff: React.Dispatch<React.SetStateAction<string>>;
    location: number;
    setLocation: React.Dispatch<React.SetStateAction<number>>;
    type: number;
    setType: React.Dispatch<React.SetStateAction<number>>;
    select: number;
    setSelect: React.Dispatch<React.SetStateAction<number>>;
    selectedMobileLines: { name: string; price: number }[];
    setSelectedMobileLines: React.Dispatch<React.SetStateAction<{ name: string; price: number }[]>>;
    tv: number;
    setTV: React.Dispatch<React.SetStateAction<number>>;
    linesArray: number[];
    setLinesArray: React.Dispatch<React.SetStateAction<number[]>>;
    totalPrice: number;
    setTotalPrice: React.Dispatch<React.SetStateAction<number>>;
}

export function Calculate({
    setSelectedTariff,
    location,
    setLocation,
    type,
    setType,
    select,
    setSelect,
    selectedMobileLines,
    setSelectedMobileLines,
    tv,
    setTV,
    linesArray,
    setLinesArray,
    totalPrice,
    setTotalPrice
}: CalculateProps) {
    const currentLanguage = useActiveLocale()
    const calculateTotalPrice = useCallback(() => {
        let total = 0;
        if (location === 0 && type === 0) {
            total += CsPacks[select]?.price ? parseInt(CsPacks[select].price) : 0;
            setSelectedTariff(CsPacks[select]?.name ? CsPacks[select].name : "")
        } else if (location === 0 && type === 1) {
            total += CsFibre[select]?.price ? parseInt(CsFibre[select].price) : 0;
            setSelectedTariff(CsFibre[select]?.name ? CsFibre[select].name : "")
        } else if (location === 1 && type === 0) {
            total += SpPacks[select]?.price ? parseInt(SpPacks[select].price) : 0;
            setSelectedTariff(SpPacks[select]?.name ? SpPacks[select].name : "")
        } else if (location === 1 && type === 1) {
            total += SpFibre[select]?.price ? parseInt(SpFibre[select].price) : 0;
            setSelectedTariff(SpFibre[select]?.name ? SpFibre[select].name : "")
        }

        total += selectedMobileLines.reduce((acc, pack) => acc + pack.price, 0);

        for (let i = 0; i < linesArray.length; i++) {
            if (linesArray[i] > 0) {
                total += Number(MobilePacks[i].price)
            }
        }

        if (tv === 1 && ((select === 9 || select === -1) || type === 1)) {
            total += 9
        }


        return total;
    }, [location, type, selectedMobileLines, tv, select, setSelectedTariff, linesArray]);

    useEffect(() => {
        setTotalPrice(calculateTotalPrice());
    }, [location, type, select, linesArray, selectedMobileLines, calculateTotalPrice, setTotalPrice]);


    const handleSetLocation = (n: number) => {
        setLocation(n);
        setSelect(-1);
        setTotalPrice(calculateTotalPrice());
    };

    const handleSetType = (n: number) => {
        setType(n);
        setSelect(-1);
        setTotalPrice(calculateTotalPrice());
    };

    const handleSelect = (index: number) => {
        // If the currently selected option is the same as the clicked option, deselect the option.
        if (select === index) {
            setSelect(-1);
        } else {
            // Otherwise, select the clicked option
            setSelect(index);
        }
    };

    const handleSetLines = (index: number, increment: number) => {
        const newLinesArray = [...linesArray];
        const totalLines = newLinesArray.reduce((acc, val) => acc + val, 0); // Total current lines
        const newTotalLines = totalLines + increment;

        // Check if the proposed increase does not exceed the total limit of 6 lines and is not less than 0.
        if (newTotalLines >= 0 && newTotalLines <= 6) {
            // Check if the proposed increase does not make the number of lines for the current mobile package less than 0.
            if (newLinesArray[index] + increment >= 0) {
                newLinesArray[index] += increment;
                setLinesArray(newLinesArray);

                // Get the selected mobile package
                const selectedMobilePack = MobilePacks[index];
                // Calculate the total price of the mobile package
                const totalPriceOfMobilePack = parseInt(selectedMobilePack.price) * increment;

                updateSelectedPacks(selectedMobilePack, totalPriceOfMobilePack);
                setTotalPrice(calculateTotalPrice());
            }
        }
    };

    const updateSelectedPacks = (mobilePack: { name: string; price: string }, totalPriceOfMobilePack: number) => {
        const newSelectedPacks = [...selectedMobileLines];
        const packIndex = newSelectedPacks.findIndex(p => p.name === mobilePack.name);

        if (packIndex !== -1) {
            newSelectedPacks[packIndex].price += totalPriceOfMobilePack;
            if (newSelectedPacks[packIndex].price <= 0) {
                newSelectedPacks.splice(packIndex, 1);
            }
        } else {
            // Only add the package if you are increasing the number of lines.
            if (totalPriceOfMobilePack > 0) {
                newSelectedPacks.push({ name: mobilePack.name, price: totalPriceOfMobilePack });
            }
        }
        setSelectedMobileLines(newSelectedPacks);
    };

    const [expanded, setExpanded] = useState(false);

    const toggleDetails = () => {
        setExpanded(!expanded);
    };

    return (
        <Wrapper>
            <AutoColumn>
                <RowCenter style={{ gap: '0.5rem' }} mb="1rem">
                    <ThemedText.ExtraLargeHeader color="secondary1">
                        <Trans>Fibre</Trans>
                    </ThemedText.ExtraLargeHeader>
                    <ThemedText.Body color="text2">
                        <Trans>Add a pack or fibre only</Trans>
                    </ThemedText.Body>
                </RowCenter>
                <ButtonBox>
                    <ButtonSelect type="button" width="250px" onClick={() => handleSetLocation(0)} active={location === 0}>
                        {location === 0 && <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '0.5rem' }} />}
                        <Trans>Province of Castellon</Trans>
                    </ButtonSelect>
                    <ButtonSelect type="button" width="250px" onClick={() => handleSetLocation(1)} active={location === 1}>
                        {location === 1 && <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '0.5rem' }} />}
                        <Trans>Rest of Spain</Trans>
                    </ButtonSelect>
                </ButtonBox>
                <ButtonWrapper>
                    <ButtonChange type="button" onClick={() => handleSetType(0)} active={type === 0}>
                        <Trans>Fibre+Mobile</Trans>
                    </ButtonChange>
                    <ButtonChange type="button" onClick={() => handleSetType(1)} active={type === 1}>
                        <Trans>Only Fibre</Trans>
                    </ButtonChange>
                </ButtonWrapper>

                {/* Castellon - Fibre+Mobile */}

                {location === 0 && type === 0 &&
                    <AutoColumn gap="0.5rem">
                        {CsPacks.map((pack, index) => (
                            <ButtonOption type="button" key={index} onClick={() => handleSelect(index)} active={select === index}>
                                <RowBetween>
                                    <FontAwesomeIcon icon={select === index ? faCheck : faSquare} fontSize="20px" />
                                    <ThemedText.MediumText>
                                        {pack.connection} +  {pack.mobile === "Unlimited" ? <Trans>Unlimited Data</Trans> : pack.mobile}
                                    </ThemedText.MediumText>
                                    <ThemedText.MediumText>{pack.price} €</ThemedText.MediumText>
                                </RowBetween>
                            </ButtonOption>
                        ))}
                    </AutoColumn>
                }

                {/* Castellon - Fibre */}

                {location === 0 && type === 1 &&
                    <AutoColumn gap="0.5rem">
                        {CsFibre.map((pack, index) => (
                            <ButtonOption type="button" key={index} onClick={() => handleSelect(index)} active={select === index}>
                                <RowBetween>
                                    <FontAwesomeIcon icon={select === index ? faCheck : faSquare} fontSize="20px" />
                                    <ThemedText.MediumText>
                                        {pack.connection}
                                    </ThemedText.MediumText>
                                    <ThemedText.MediumText>{pack.price} €</ThemedText.MediumText>
                                </RowBetween>
                            </ButtonOption>
                        ))}
                    </AutoColumn>
                }

                {/* Spain - Fibre+Mobile */}

                {location === 1 && type === 0 &&
                    <AutoColumn gap="0.5rem">
                        {SpPacks.map((pack, index) => (
                            <ButtonOption type="button" key={index} onClick={() => handleSelect(index)} active={select === index}>
                                <RowBetween>
                                    <FontAwesomeIcon icon={select === index ? faCheck : faSquare} fontSize="20px" />
                                    <ThemedText.MediumText>
                                        {pack.connection} +  {pack.mobile === "Unlimited" ? <Trans>Unlimited Data</Trans> : pack.mobile}
                                    </ThemedText.MediumText>
                                    <ThemedText.MediumText>{pack.price} €</ThemedText.MediumText>
                                </RowBetween>
                            </ButtonOption>
                        ))}
                    </AutoColumn>
                }


                {/* Spain - Fibre */}

                {location === 1 && type === 1 &&
                    <AutoColumn gap="0.5rem">
                        {SpFibre.map((pack, index) => (
                            <ButtonOption type="button" key={index} onClick={() => handleSelect(index)} active={select === index}>
                                <RowBetween>
                                    <FontAwesomeIcon icon={select === index ? faCheck : faSquare} fontSize="20px" />
                                    <ThemedText.MediumText>
                                        {pack.connection}
                                    </ThemedText.MediumText>
                                    <ThemedText.MediumText>{pack.price} €</ThemedText.MediumText>
                                </RowBetween>
                            </ButtonOption>
                        ))}
                    </AutoColumn>
                }

                {/* Mobile */}

                <RowCenter style={{ gap: '0.5rem' }} my="2rem">
                    <ThemedText.ExtraLargeHeader color="secondary1">
                        <Trans>Mobile</Trans>
                    </ThemedText.ExtraLargeHeader>
                    <ThemedText.Body color="text2">
                        <Trans>You can add up to 6 lines</Trans>
                    </ThemedText.Body>
                </RowCenter>

                <AutoColumn gap="0.5rem">
                    {MobilePacks.map((pack, index) => (
                        <StyledRowBetween key={index}>
                            {isMobile ?
                                <StyledColumn>
                                    <ThemedText.MediumText>
                                        {pack.connection}
                                    </ThemedText.MediumText>
                                    <ThemedText.MediumText>
                                        <Trans>Unlimited Calls</Trans>
                                    </ThemedText.MediumText>
                                </StyledColumn>
                                :
                                <>
                                    <StyledRow>
                                        <ThemedText.MediumText>
                                            {pack.connection === "Unlimited" ? <Trans>Unlimited Data</Trans> : pack.connection}
                                        </ThemedText.MediumText>
                                    </StyledRow>
                                    <StyledRow>
                                        <ThemedText.MediumText>
                                            <Trans>Unlimited calls</Trans>
                                        </ThemedText.MediumText>
                                    </StyledRow>
                                </>
                            }

                            <StyledRow>
                                <ThemedText.MediumText>{pack.price} €</ThemedText.MediumText>
                            </StyledRow>
                            <StyledRow>
                                <ButtonLines type="button" onClick={() => handleSetLines(index, -1)}>
                                    <FontAwesomeIcon icon={faCircleMinus} fontSize="20px" color={linesArray[index] === 0 ? "#838383" : "#CCEDFC"} />
                                </ButtonLines>
                                <ThemedText.MediumText>{linesArray[index]}</ThemedText.MediumText>
                                <ButtonLines type="button" onClick={() => handleSetLines(index, +1)}>
                                    <FontAwesomeIcon icon={faCirclePlus} fontSize="20px" color="#CCEDFC" />
                                </ButtonLines>
                            </StyledRow>
                        </StyledRowBetween>
                    ))}
                </AutoColumn>

                {/* TV */}

                <RowCenter style={{ gap: '0.5rem' }} my="2rem">
                    <ThemedText.ExtraLargeHeader color="secondary1">
                        <Trans>TV</Trans>
                    </ThemedText.ExtraLargeHeader>
                    <ThemedText.Body color="text2">
                        <Trans>Contract our television service</Trans>
                    </ThemedText.Body>
                </RowCenter>

                <AutoColumn>
                    <ButtonOption type="button" onClick={() => setTV(tv === 0 ? 1 : 0)} active={tv === 1}>
                        <RowBetween>
                            <FontAwesomeIcon icon={tv === 1 ? faCheck : faSquare} fontSize="20px" />
                            <ThemedText.MediumText>TV</ThemedText.MediumText>
                            {select !== -1 && type === 0 ?
                                <RowRight width="fit-content" style={{ gap: '0.2rem' }}>
                                    <TextThrought>9 €</TextThrought>
                                    <FontAwesomeIcon icon={faArrowRight} fontSize="20px" />
                                    <ThemedText.MediumHeader color="green1">0 €</ThemedText.MediumHeader>
                                </RowRight>
                                :
                                <ThemedText.MediumHeader>9 €</ThemedText.MediumHeader>
                            }
                        </RowBetween>
                    </ButtonOption>
                </AutoColumn>


            </AutoColumn>

            {/* Right Card: Detailed selection data */}

            {(totalPrice > 0 || !isMobile) &&
                <StyledDarkBlueCard expanded={expanded}>
                    <ResponsiveBox>
                        {isMobile ?
                            <AutoColumn gap="1rem">
                                <ThemedText.MediumText color="secondary1" textAlign="center" mt="0.5rem">
                                    <Trans>YOUR COMBINATION</Trans>
                                </ThemedText.MediumText>
                                <ButtonSecondary type="button" onClick={toggleDetails} width="fit-content" height="10px">
                                    <RowCenter style={{ gap: '0.2rem' }}>
                                        <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                                        {expanded ? <Trans>Close Details</Trans> : <Trans>See Details</Trans>}
                                    </RowCenter>
                                </ButtonSecondary>
                            </AutoColumn>
                            :
                            <ThemedText.MediumText color="secondary1" textAlign="center">
                                <Trans>YOUR COMBINATION</Trans>
                            </ThemedText.MediumText>
                        }
                        {!isMobile && (location === 0 && type === 0 && CsPacks[select]) && (
                            <RowBetween>
                                {CsPacks[select].mobile === "Unlimited" ?
                                    <ThemedText.Body color="text2">Pack {CsPacks[select].connection} + <Trans>Unlimited Data</Trans></ThemedText.Body>
                                    :
                                    <ThemedText.Body color="text2">{CsPacks[select].name}</ThemedText.Body>}
                                <ThemedText.Body color="text2">{CsPacks[select].price} €</ThemedText.Body>
                            </RowBetween>
                        )}
                        {!isMobile && (location === 0 && type === 1 && CsFibre[select]) && (
                            <RowBetween>
                                <ThemedText.Body color="text2">{CsFibre[select].name}</ThemedText.Body>
                                <ThemedText.Body color="text2">{CsFibre[select].price} €</ThemedText.Body>
                            </RowBetween>
                        )}
                        {!isMobile && (location === 1 && type === 0 && SpPacks[select]) && (
                            <RowBetween>
                                {SpPacks[select].mobile === "Unlimited" ?
                                    <ThemedText.Body color="text2">Pack {SpPacks[select].connection} + <Trans>Unlimited Data</Trans></ThemedText.Body>
                                    :
                                    <ThemedText.Body color="text2">{SpPacks[select].name}</ThemedText.Body>}
                                <ThemedText.Body color="text2">{SpPacks[select].price} €</ThemedText.Body>
                            </RowBetween>
                        )}
                        {!isMobile && (location === 1 && type === 1 && SpFibre[select]) && (
                            <RowBetween>
                                <ThemedText.Body color="text2">{SpFibre[select].name}</ThemedText.Body>
                                <ThemedText.Body color="text2">{SpFibre[select].price} €</ThemedText.Body>
                            </RowBetween>
                        )}
                        {/* Show the name of the mobile line only if there are added lines and if there are added lines, show all of them. */}
                        {!isMobile && linesArray.map((lines, index) => {
                            const mobilePack = MobilePacks[index];
                            const linesToAdd = Array.from({ length: lines }, (_, i) => i + 1);
                            const pack = currentLanguage === "es-ES" ? mobilePack.name.replace(/mobile/gi, "Móvil").replace(/unlimited/gi, "Ilimitado") : mobilePack.name
                            return linesToAdd.map(lineIndex => (
                                <RowBetween key={`${index}-${lineIndex}`}>
                                    <ThemedText.Body color="text2">{pack}</ThemedText.Body>
                                    <ThemedText.Body color="text2">{mobilePack.price} €</ThemedText.Body>
                                </RowBetween>
                            ));
                        })}
                        {!isMobile && tv === 1 &&
                            <RowBetween>
                                <ThemedText.Body color="text2">TV</ThemedText.Body>
                                {select !== -1 && type === 0 ?
                                    <ThemedText.Body color="text2">0 €</ThemedText.Body>
                                    :
                                    <ThemedText.Body color="text2">9 €</ThemedText.Body>
                                }
                            </RowBetween>
                        }
                        <AutoColumn gap="0.2rem">
                            <ThemedText.ExtraLargeHeader color="green1" textAlign="center">
                                {totalPrice}
                            </ThemedText.ExtraLargeHeader>
                            <ThemedText.Body color="green1" textAlign="center">
                                €/ <Trans>Month</Trans>
                            </ThemedText.Body>
                            <ThemedText.Body color="text2" textAlign="center">
                                <Trans>vat included</Trans>
                            </ThemedText.Body>
                        </AutoColumn>
                    </ResponsiveBox>

                    {isMobile &&
                        <StyledDetailsContent expanded={expanded}>
                            {(location === 0 && type === 0 && CsPacks[select]) && (
                                <RowBetween mb="0.2rem">
                                    {CsPacks[select].mobile === "Unlimited" ?
                                        <ThemedText.Body color="text2">Pack {CsPacks[select].connection} + <Trans>Unlimited Data</Trans></ThemedText.Body>
                                        :
                                        <ThemedText.Body color="text2">{CsPacks[select].name}</ThemedText.Body>}
                                    <ThemedText.Body color="text2">{CsPacks[select].price} €</ThemedText.Body>
                                </RowBetween>
                            )}
                            {(location === 0 && type === 1 && CsFibre[select]) && (
                                <RowBetween mb="0.2rem">
                                    <ThemedText.Body color="text2">{CsFibre[select].name}</ThemedText.Body>
                                    <ThemedText.Body color="text2">{CsFibre[select].price} €</ThemedText.Body>
                                </RowBetween>
                            )}
                            {(location === 1 && type === 0 && SpPacks[select]) && (
                                <RowBetween mb="0.2rem">
                                    {SpPacks[select].mobile === "Unlimited" ?
                                        <ThemedText.Body color="text2">Pack {SpPacks[select].connection} + <Trans>Unlimited Data</Trans></ThemedText.Body>
                                        :
                                        <ThemedText.Body color="text2">{SpPacks[select].name}</ThemedText.Body>}
                                    <ThemedText.Body color="text2">{SpPacks[select].price} €</ThemedText.Body>
                                </RowBetween>
                            )}
                            {(location === 1 && type === 1 && SpFibre[select]) && (
                                <RowBetween mb="0.2rem">
                                    <ThemedText.Body color="text2">{SpFibre[select].name}</ThemedText.Body>
                                    <ThemedText.Body color="text2">{SpFibre[select].price} €</ThemedText.Body>
                                </RowBetween>
                            )}
                            {/* Show the name of the mobile line only if there are added lines and if there are added lines, show all of them. */}
                            {linesArray.map((lines, index) => {
                                const mobilePack = MobilePacks[index];
                                const linesToAdd = Array.from({ length: lines }, (_, i) => i + 1);
                                let wordTrans = mobilePack.name.substring(mobilePack.name.indexOf(" ") + 1)
                                if (currentLanguage === "es-ES" && mobilePack.name.split(" ")[1] === "Unlimited") {
                                    wordTrans = "Ilimitado"
                                }
                                const pack = currentLanguage === "es-ES" ? "Móvil " + wordTrans : mobilePack.name
                                return linesToAdd.map(lineIndex => (
                                    <RowBetween key={`${index}-${lineIndex}`} mb="0.2rem">
                                        <ThemedText.Body color="text2">{pack}</ThemedText.Body>
                                        <ThemedText.Body color="text2">{mobilePack.price} €</ThemedText.Body>
                                    </RowBetween>
                                ));
                            })}
                            {!isMobile && tv === 1 &&
                                <RowBetween>
                                    <ThemedText.Body color="text2">TV</ThemedText.Body>
                                    {select !== -1 && type === 0 ?
                                        <ThemedText.Body color="text2">0 €</ThemedText.Body>
                                        :
                                        <ThemedText.Body color="text2">9 €</ThemedText.Body>
                                    }
                                </RowBetween>
                            }
                        </StyledDetailsContent>
                    }

                </StyledDarkBlueCard>}


        </Wrapper>
    );
}
