import Slide from "react-reveal";
import { faCircleInfo, faHouseSignal, faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { ButtonLink } from "components/Button";
import { BlueCard } from "components/Card";
import { AutoColumn } from "components/Column";
import { RowBetween } from "components/Row";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";
import { MouseoverTooltip } from "components/Tooltip";

const ResponsiveBox = styled.div`
    display grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        grid-template-columns: 1fr;
        place-items: center normal;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        grid-template-columns: 1fr;
        place-items: center normal;
    `};
`;

const Divider = styled.div`
    border: 1px solid ${({ theme }) => theme.secondary1};
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const Row = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`

const WrapperIcon = styled.div`
  display: flex;
  margin-left: 0.1rem;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
`


export function OneThousand() {
    return (
        <ResponsiveBox>
            <Slide left>
                <BlueCard height={320} width={isMobile ? "100%" : "350px"}>
                    <AutoColumn>
                        <RowBetween>
                            <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                            <Row>
                                <ThemedText.LargeText color="#fff">
                                    <Trans>Fibre</Trans> 1 GB
                                </ThemedText.LargeText>
                                <MouseoverTooltip
                                    text={
                                        <AutoColumn gap="0.2rem">
                                            <ThemedText.SubHeader>
                                                - <Trans>Installation and Router included</Trans>
                                            </ThemedText.SubHeader>
                                            <ThemedText.SubHeader>
                                                - <Trans>12 months of commitment</Trans>
                                            </ThemedText.SubHeader>
                                            <ThemedText.SubHeader>
                                                - <Trans>Penalty of 150€</Trans>
                                            </ThemedText.SubHeader>
                                        </AutoColumn>
                                    }
                                >
                                    <WrapperIcon>
                                        <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                    </WrapperIcon>
                                </MouseoverTooltip>
                            </Row>
                        </RowBetween>
                        <Divider />
                        <RowBetween>
                            <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                            <AutoColumn>
                                <ThemedText.LargeText color="#fff" textAlign="right">
                                    35 GB
                                </ThemedText.LargeText>
                                <ThemedText.LargeText color="#fff" textAlign="right">
                                    <Trans>Unlimited calls</Trans>
                                </ThemedText.LargeText>
                            </AutoColumn>
                        </RowBetween>
                        <Divider />
                        <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                            29€/<Trans>Month</Trans>
                        </ThemedText.ExtraLargeText>
                        <ButtonLink to="/calcular/0000">
                            <Trans>Hire</Trans>
                        </ButtonLink>
                    </AutoColumn>
                </BlueCard>
                <BlueCard height={320} width={isMobile ? "100%" : "350px"}>
                    <AutoColumn>
                        <RowBetween>
                            <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                            <Row>
                                <ThemedText.LargeText color="#fff">
                                    <Trans>Fibre</Trans> 1 GB
                                </ThemedText.LargeText>
                                <MouseoverTooltip
                                    text={
                                        <AutoColumn gap="0.2rem">
                                            <ThemedText.SubHeader>
                                                - <Trans>Installation and Router included</Trans>
                                            </ThemedText.SubHeader>
                                            <ThemedText.SubHeader>
                                                - <Trans>12 months of commitment</Trans>
                                            </ThemedText.SubHeader>
                                            <ThemedText.SubHeader>
                                                - <Trans>Penalty of 150€</Trans>
                                            </ThemedText.SubHeader>
                                        </AutoColumn>
                                    }
                                >
                                    <WrapperIcon>
                                        <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                    </WrapperIcon>
                                </MouseoverTooltip>
                            </Row>
                        </RowBetween>
                        <Divider />
                        <RowBetween>
                            <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                            <AutoColumn>
                                <ThemedText.LargeText color="#fff" textAlign="right">
                                    70 GB
                                </ThemedText.LargeText>
                                <ThemedText.LargeText color="#fff" textAlign="right">
                                    <Trans>Unlimited calls</Trans>
                                </ThemedText.LargeText>
                            </AutoColumn>
                        </RowBetween>
                        <Divider />
                        <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                            34€/<Trans>Month</Trans>
                        </ThemedText.ExtraLargeText>
                        <ButtonLink to="/calcular/0011">
                            <Trans>Hire</Trans>
                        </ButtonLink>
                    </AutoColumn>
                </BlueCard>
            </Slide>

            <Slide left>
                <BlueCard height={320} width={isMobile ? "100%" : "350px"}>
                    <AutoColumn>
                        <RowBetween>
                            <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                            <Row>
                                <ThemedText.LargeText color="#fff">
                                    <Trans>Fibre</Trans> 1 GB
                                </ThemedText.LargeText>
                                <MouseoverTooltip
                                    text={
                                        <AutoColumn gap="0.2rem">
                                            <ThemedText.SubHeader>
                                                - <Trans>Installation and Router included</Trans>
                                            </ThemedText.SubHeader>
                                            <ThemedText.SubHeader>
                                                - <Trans>12 months of commitment</Trans>
                                            </ThemedText.SubHeader>
                                            <ThemedText.SubHeader>
                                                - <Trans>Penalty of 150€</Trans>
                                            </ThemedText.SubHeader>
                                        </AutoColumn>
                                    }
                                >
                                    <WrapperIcon>
                                        <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                    </WrapperIcon>
                                </MouseoverTooltip>
                            </Row>
                        </RowBetween>
                        <Divider />
                        <RowBetween>
                            <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                            <AutoColumn>
                                <ThemedText.LargeText color="#fff" textAlign="right">
                                    140 GB
                                </ThemedText.LargeText>
                                <ThemedText.LargeText color="#fff" textAlign="right">
                                    <Trans>Unlimited calls</Trans>
                                </ThemedText.LargeText>
                            </AutoColumn>
                        </RowBetween>
                        <Divider />
                        <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                            40€/<Trans>Month</Trans>
                        </ThemedText.ExtraLargeText>
                        <ButtonLink to="/calcular/0022">
                            <Trans>Hire</Trans>
                        </ButtonLink>
                    </AutoColumn>
                </BlueCard>
                <BlueCard height={320} width={isMobile ? "100%" : "350px"}>
                    <AutoColumn>
                        <RowBetween>
                            <FontAwesomeIcon icon={faHouseSignal} fontSize="40px" color="#ccedfc" />
                            <Row>
                                <ThemedText.LargeText color="#fff">
                                    <Trans>Fibre</Trans> 1 GB
                                </ThemedText.LargeText>
                                <MouseoverTooltip
                                    text={
                                        <AutoColumn gap="0.2rem">
                                            <ThemedText.SubHeader>
                                                - <Trans>Installation and Router included</Trans>
                                            </ThemedText.SubHeader>
                                            <ThemedText.SubHeader>
                                                - <Trans>12 months of commitment</Trans>
                                            </ThemedText.SubHeader>
                                            <ThemedText.SubHeader>
                                                - <Trans>Penalty of 150€</Trans>
                                            </ThemedText.SubHeader>
                                        </AutoColumn>
                                    }
                                >
                                    <WrapperIcon>
                                        <FontAwesomeIcon icon={faCircleInfo} fontSize="18px" />
                                    </WrapperIcon>
                                </MouseoverTooltip>
                            </Row>
                        </RowBetween>
                        <Divider />
                        <RowBetween>
                            <FontAwesomeIcon icon={faMobileScreen} fontSize="40px" color="#ccedfc" />
                            <AutoColumn>
                                <ThemedText.LargeText color="#fff" textAlign="right">
                                    <Trans>Unlimited Data</Trans>
                                </ThemedText.LargeText>
                                <ThemedText.LargeText color="#fff" textAlign="right">
                                    <Trans>Unlimited calls</Trans>
                                </ThemedText.LargeText>
                            </AutoColumn>
                        </RowBetween>
                        <Divider />
                        <ThemedText.ExtraLargeText textAlign="center" color="green1" mb="1rem">
                            45€/<Trans>Month</Trans>
                        </ThemedText.ExtraLargeText>
                        <ButtonLink to="/calcular/0033">
                            <Trans>Hire</Trans>
                        </ButtonLink>
                    </AutoColumn>
                </BlueCard>
            </Slide>
        </ResponsiveBox>
    );
}
