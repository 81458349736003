import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ButtonChange, ButtonExternalLink, ButtonLink, ButtonSelect } from "components/Button";
import { Trans } from "@lingui/macro";
import { useState } from "react";
import { ThreeHundred } from "./PacksSpain/ThreeHundred";
import { SixHundred } from "./PacksSpain/SixHundred";
import { ThemedText } from "theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseSignal, faLocationDot, faMobileScreen, faPhoneVolume, faPlus, faTv } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { isMobile } from "utils/userAgent";
import { OneThousand } from "./PacksCastellon/OneThousand";


const Wrapper = styled(AutoColumn)`
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 2rem;
    margin-top: 10rem;
    margin-bottom: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        max-width: 800px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        max-width: 500px;
    `};
`;

const ButtonWrapper = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-bottom: 1rem;
    background: ${({ theme }) => theme.primary5};
    border-radius: 24px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    }
`;

const Divider = styled.div`
    border-top: 1px solid ${({ theme }) => theme.secondary1};
    margin-top: 1rem;
    margin-bottom: 1rem;
`;


const ResponsiveBox = styled.div`
    display flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        gap: 2rem;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        gap: 2rem;
    `};
`;


const ButtonBox = styled.div`
    display flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        gap: 0.5rem;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        gap: 0.5rem;
    `};
`;

const Row = styled.div`
    display flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

const Column = styled.div`
    display flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
`;

const StyledButtonLink = styled(ButtonLink)`
    background: ${({ theme }) => theme.secondary2};
    height: 10px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
    &:hover {
        color: ${({ theme }) => theme.primary2} !important;
        scale: 1.2;
    }
`;


export function Packs() {
    const [pack, setPack] = useState(0)
    const [location, setLocation] = useState(0)
    const phoneNumber = "+34648136914"
    return (
        <Wrapper>
            <Divider />
            <ResponsiveBox>
                <AutoColumn>
                    <ThemedText.MediumHeader style={{ textTransform: 'uppercase' }} textAlign={isMobile ? "center" : "left"}>
                        <Trans>Hire for fiber and mobile, the perfect</Trans>
                    </ThemedText.MediumHeader>
                    <ThemedText.MediumHeader style={{ textTransform: 'uppercase' }} textAlign={isMobile ? "center" : "left"}>
                        <Trans>combination of speed and savings</Trans>.
                    </ThemedText.MediumHeader>
                </AutoColumn>
                <Column>
                    <Row>
                        <FontAwesomeIcon icon={faHouseSignal} fontSize="30px" color="#ccedfc" />
                        <FontAwesomeIcon icon={faPlus} fontSize="15px" color="#ccedfc" />
                        <FontAwesomeIcon icon={faMobileScreen} fontSize="30px" color="#ccedfc" />
                        <FontAwesomeIcon icon={faPlus} fontSize="15px" color="#ccedfc" />
                        <FontAwesomeIcon icon={faTv} fontSize="30px" color="#ccedfc" />
                    </Row>
                    <StyledButtonLink to="/calcular" width="fit-content">
                        <Trans>Calculate</Trans>
                    </StyledButtonLink>
                </Column>
            </ResponsiveBox>
            <Divider />
            <ButtonBox>
                <ButtonSelect width="250px" onClick={() => setLocation(0)} active={location === 0}>
                    {location === 0 && <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '0.5rem' }} />}
                    <Trans>Province of Castellon</Trans>
                </ButtonSelect>
                <ButtonSelect width="250px" onClick={() => setLocation(1)} active={location === 1}>
                    {location === 1 && <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '0.5rem' }} />}
                    <Trans>Rest of Spain</Trans>
                </ButtonSelect>
            </ButtonBox>
            {location === 1 &&
                <ButtonWrapper>
                    <ButtonChange onClick={() => setPack(0)} active={pack === 0}>
                        300 MB
                    </ButtonChange>
                    <ButtonChange onClick={() => setPack(1)} active={pack === 1}>
                        600 MB
                    </ButtonChange>
                </ButtonWrapper>}
            {location === 0 ? <OneThousand /> : pack === 0 ? <ThreeHundred /> : <SixHundred />}
            <ThemedText.MediumHeader textAlign="center" color="text2" mt="4rem">
                <Trans>Choose your best combination, or if you prefer, call us or contact us via WhatsApp</Trans>
            </ThemedText.MediumHeader>
            <Row>
                <ButtonExternalLink width="fit-content" href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                    <StyledIcon icon={faPhoneVolume} fontSize="40px" />
                </ButtonExternalLink>
                <ButtonExternalLink width="fit-content" href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                    <StyledIcon icon={faWhatsapp} fontSize="40px" />
                </ButtonExternalLink>
            </Row>
        </Wrapper>
    );
}
