import Footer from "components/Footer";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import Header from "../components/Header";
import { Home } from "./Home";
import { FibreMobile } from "./FibreMobile";
import { Fibre } from "./Fibre";
import { Mobile } from "./Mobile";
import { Television } from "./Television";
import { CustomerService } from "./CustomerService";
import { Recommended } from "./Recommended";
import { Calculator } from "./Calculator";
import CookieBanner from "components/Cookies/CookieBanner";
import { useEffect } from "react";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  z-index: 1;
  color: #fff;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`;

const FooterWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  bottom: 0;
`;

const Marginer = styled.div`
  margin-top: 5rem;
`;

const ScrollToTopOnPathChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default function App() {
  return (
    <AppWrapper>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <BodyWrapper>
        <CookieBanner />
        <ScrollToTopOnPathChange />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/fibra-movil" element={<FibreMobile />} />
          <Route path="/fibra" element={<Fibre />} />
          <Route path="/movil" element={<Mobile />} />
          <Route path="/tv" element={<Television />} />
          <Route path="/atencion-cliente" element={<CustomerService />} />
          <Route path="/recomendados" element={<Recommended />} />
          <Route path="/calcular" element={<Calculator />} />
          <Route path="/calcular/:id" element={<Calculator />} />
        </Routes>
        <Marginer />
      </BodyWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </AppWrapper>
  );
}
