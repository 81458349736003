import { faSquareFacebook, faSquareInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { AutoColumn } from 'components/Column';
import { RowCenter } from 'components/Row';
import styled from 'styled-components';
import { ThemedText } from 'theme';

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 2rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
      max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
      max-width: 500px;
  `};
`;


const SocialNetworks = styled.a`
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.secondary1};
  :hover {
    color: ${({ theme }) => theme.secondary2};
    transform: scale(0.8);
  }
`;

export function ContactNetworks() {

    return (
        <Wrapper>
            <AutoColumn gap="1rem">
                <ThemedText.LargeHeader color="text2" textAlign="center">
                    <Trans>Contact Networks</Trans>
                </ThemedText.LargeHeader>
                <RowCenter style={{ gap: '1rem' }}>
                    <SocialNetworks href="https://www.instagram.com/vidpaytelecom?igsh=ZXo5aHUxdTBsN3hl" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSquareInstagram} fontSize="40px" />
                    </SocialNetworks>
                    <SocialNetworks href="https://www.facebook.com/vidpay.telecom" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSquareFacebook} fontSize="40px" />
                    </SocialNetworks>
                    <SocialNetworks href="mailto:info@vidpay.es" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faEnvelope} fontSize="40px" />
                    </SocialNetworks>
                </RowCenter>
            </AutoColumn>
        </Wrapper>
    );
};