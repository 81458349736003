import Slide from "react-reveal";
import styled from "styled-components/macro";
import TV1 from "assets/images/TV/tv.png"
import { ThemedText } from "theme";
import { Trans } from "@lingui/macro";
import { TvInfo } from "./components/TvInfo";

const PageWrapper = styled.header`
  width: 100%;
  position: relative;
  margin-top: 8rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 7rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Body = styled(ThemedText.Title)`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 12%;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 12%;
  `};
`;


export function Television() {
  return (
    <PageWrapper>
      <Image src={TV1} alt="tv-img" />
      <Body>
        <Slide left>
          <Trans>Connect to a World of Limitless Entertainment</Trans>
        </Slide>
      </Body>
      <TvInfo />
    </PageWrapper>
  );
}
